import {PostgrestClient} from '@supabase/postgrest-js'

const REST_AUTH_URL = process.env.REACT_APP_API_REST_URL
const REST_URL = process.env.REACT_APP_API_PROGRAM_REST_URL
const REST_CRM_URL = process.env.REACT_APP_API_CRM_REST_URL
const postgrest = new PostgrestClient(REST_URL)
const crmPostgrest = new PostgrestClient(REST_CRM_URL)
const authPostgrest = new PostgrestClient(REST_AUTH_URL)

export {postgrest, crmPostgrest, authPostgrest}
