import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table"
import { RealtimeProgramChart } from "../../program/program/interfaces/program-chart-result.interface"
import { generateCurrencyFormat } from "../../../../helpers/generate-currency-format"
import { useState } from 'react'

interface ProgramChartDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export const programChartColumns: ColumnDef<RealtimeProgramChart>[] = [
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "slug",
    header: "Slug",
  },
  {
    accessorKey: "donations[0].total_donates",
    header: "Total Donation",
    accessorFn: row => row.donations?.at(0)?.total_donates,
    cell: (row) => {
      const totalDonates = row.getValue() as number ?? 0
      return <div>{generateCurrencyFormat(totalDonates)}</div>
    }
  },
  {
    accessorKey: "donations[0].total_trx",
    header: "Total Donor",
    accessorFn: row => row.donations?.at(0)?.total_trx,
    cell: (row) => {
      const totalTrx = row.getValue() as number ?? 0
      return <div>{totalTrx}</div>
    }
  },
];

export function ProgramChartDataTable<TData, TValue>({
  columns,
  data,
}: ProgramChartDataTableProps<TData, TValue>) {

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination
    },
    onPaginationChange: setPagination,
    debugTable: true
  })

  return (
    <div className="table-responsive">
      <table className="table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="text-gray-600 fw-semibold">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No Properties Found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div
        className="pagination pagination-sm m-0 float-right"
        style={{
          display: 'flex',
          flex: 1,
          justifyItems: 'center',
          alignItems: 'center'
        }}>
        <button
          className="page-link"
          onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()}>
          <span className="page-item">{'<<'}</span>
        </button>
        <button
          className="page-link"
          onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          <span className="page-item">{'<'}</span>
        </button>

        <span className="mx-2" style={{
          fontSize: '1rem'
        }}>
          Page{' '}
          <strong>
            {pagination?.pageIndex + 1} of {table.getPageCount()}
          </strong>
        </span>

        <button
          className="page-link"
          onClick={() => {
            table.nextPage()
          }} disabled={!table.getCanNextPage()}>
          <span className="page-item">{'>'}</span>
        </button>
        <button
          className="page-link"
          onClick={() => table.lastPage()} disabled={!table.getCanNextPage()}>
          <span className="page-item">{'>>'}</span>
        </button>
        {/* <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select> */}
      </div>
    </div>
  )
}
