import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {z} from 'zod'
import {
  DonationSingleResultInterface,
  TotalDonationV2Data,
} from '../interfaces/donation-single-result.interface'
import {
  DonationData,
  DonationResultInterface,
  RestDonationInterface,
} from '../interfaces/donation-result.interface'
import {
  GetAllDonationParam,
  GetAllDonation,
  DeleteDonation,
  SubmitDonation,
  UpdateDonation,
  ShowDonation,
  GetAllDonationByInternalMemo,
  GetAllDonationByWorkOder,
  GetAllDonationByCustomer,
  GetAllDonationByAffiliator,
  TotalDonationV2,
} from '../providers/donation.provider'
import moment from 'moment'
import {authPostgrest, crmPostgrest, postgrest} from '../../../../../api/postgrest'
import {
  dateTimeISOFormatterEndDate,
  dateTimeISOFormatterStartDate,
  timeDateFormatter,
} from '../../../../../../_metronic/helpers/datetime-formatter'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'

interface NavList {
  name: string
  reference: string
}

interface DonationState {
  donationLoading: boolean
  donationItems: DonationData[]
  donationSelectDatas: SelectData[]
  donationModel?: DonationResultInterface
  getDonation: (params: GetAllDonationParam) => void
  getDonationByCustomer?: (params: GetAllDonationParam) => void
  getDonationByInternalMemo: (internalMemoId: string, params: GetAllDonationParam) => void
  getDonationByWorkOrder: (workOrderId: string, params: GetAllDonationParam) => void
  onDelete: (id: string, params: GetAllDonationParam) => void
  navList: NavList[]
  activeNavIndex: number
  setActiveNavIndex: (index: number) => void
  selectedDonation: DonationData
  setSelectedDonation: (data: DonationData) => void
  getDonationDetail: (id: string) => void
  role: string
  setRole: (role: string) => void

  //all donation by affiliator
  donationByAffiliatorLoading: boolean
  donationByAffiliatorItems: DonationData[]
  donationByAffiliatorSelectDatas: SelectData[]
  donationByAffiliatorModel?: DonationResultInterface
  getDonationByAffiliator: (id: string, params: GetAllDonationParam) => void

  totalDonationV2: (params: GetAllDonationParam) => void
  totalDonationV2Item: TotalDonationV2Data
  totalDonationV2Loading: boolean

  getRestDonation: (page: number, companyIds: string[]) => Promise<void>
  restDonations: DonationData[]
  restDonationModel: RestDonationInterface | null
  loadingRestDonations: boolean

  getRestDonationAff: (
    page: number,
    companyIds: string[],
    affCode: string,
    currentCommunity: string,
    startDate: Date,
    endDate: Date,
    isExport: boolean
  ) => Promise<void>
  exportLoading: boolean
}

export const useDonationStore = create<DonationState>((set, get) => ({
  activeNavIndex: 0,
  setActiveNavIndex: (index: number) => {
    set({activeNavIndex: index})
  },
  navList: [
    {
      name: 'Retails',
      reference: 'customers',
    },
    {
      name: 'Corporate',
      reference: 'customers_corporate',
    },
    {
      name: 'Community',
      reference: 'customers_community',
    },
  ],
  donationLoading: false,
  donationItems: [],
  donationSelectDatas: [],
  donationModel: undefined,
  role: '',
  setRole: (role: string) => {
    set({role: role})
  },
  getDonation: async (params: GetAllDonationParam) => {
    set({
      donationLoading: true,
      donationItems: [],
      donationSelectDatas: [],
    })
    const response = await GetAllDonation(params)
    set({donationModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByCustomer: async (params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByCustomer(params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByInternalMemo: async (internalMemoId: string, params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByInternalMemo(internalMemoId, params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByWorkOrder: async (workOderId: string, params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByWorkOder(workOderId, params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  onDelete: async (id: string, params: GetAllDonationParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({donationLoading: true})
      const response = await DeleteDonation(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getDonation(params)
      set({donationLoading: false})
    }
  },
  selectedDonation: undefined,
  setSelectedDonation: (data: DonationData) => {
    set({selectedDonation: data})
  },
  getDonationDetail: async (id: string) => {
    const response = await ShowDonation(id)
    if (response.status) {
      set({selectedDonation: response.data})
    }
  },

  donationByAffiliatorLoading: false,
  donationByAffiliatorItems: [],
  donationByAffiliatorSelectDatas: [],
  donationByAffiliatorModel: undefined,
  getDonationByAffiliator: async (id: string, params: GetAllDonationParam) => {
    set({donationByAffiliatorLoading: true})
    const response = await GetAllDonationByAffiliator(id, params)
    set({donationByAffiliatorModel: response})
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response?.data?.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationByAffiliatorItems: response.data.items,
          donationByAffiliatorSelectDatas: transformDatas,
        })
      } else {
        set({
          donationByAffiliatorItems: [],
          donationByAffiliatorSelectDatas: [],
        })
      }
    } else {
      set({
        donationByAffiliatorItems: [],
        donationByAffiliatorSelectDatas: [],
      })
    }
    set({donationByAffiliatorLoading: false})
  },
  totalDonationV2Loading: false,
  totalDonationV2Item: {},
  totalDonationV2: async (params: GetAllDonationParam) => {
    set({
      totalDonationV2Loading: true,
    })
    const response = await TotalDonationV2(params)
    if (response?.status) {
      if (response?.data) {
        set({
          totalDonationV2Item: response?.data,
        })
      } else {
        set({
          totalDonationV2Item: {},
        })
      }
    } else {
      set({
        totalDonationV2Item: {},
      })
    }
    set({totalDonationV2Loading: false})
  },
  loadingRestDonations: true,
  restDonations: [],
  restDonationModel: null,
  getRestDonation: async (page: number, companyIds: string[]) => {
    set({loadingRestDonations: true, restDonations: []})

    const start = (page - 1) * 10 // Calculate the start index
    const end = start + 10 - 1 // Calculate the end index

    const responses = await postgrest
      .from('donations')
      .select(
        `
          *,
          payments (id, name, description),
          programs (id, title, program_type)
        `,
        {
          count: 'exact',
        }
      )
      .in('company_id', companyIds)
      .is('deleted_at', null)
      .order('transfer_date', {ascending: false})
      .range(start, end)

    const totalPages = Math.ceil(responses.count / 10)
    set({
      restDonationModel: {
        ...(responses as RestDonationInterface),
        total_pages: totalPages,
      },
    })

    const donations = responses.data as DonationData[]
    for (const donation of donations) {
      const companyResp = await authPostgrest
        .from('companies')
        .select('id, name')
        .eq('id', donation.company_id)
        .limit(1)
        .single()
      donation.owner = companyResp.data?.name ?? ''

      const channelResp = await crmPostgrest
        .from('prospect_channels')
        .select('id, name')
        .eq('id', donation.channel_id)
        .limit(1)
      donation.channel_name = channelResp.data?.at(0)?.name ?? ''

      const customerResp = await crmPostgrest
        .from('customers')
        .select('id, customer_values')
        .eq('id', donation.customer_id)
        .limit(1)
      donation.customer_values = customerResp.data?.at(0)?.customer_values
      donation.customer_email = customerResp.data?.at(0)?.customer_values['email'] ?? ''
      donation.customer_phone = customerResp.data?.at(0)?.customer_values['nomor-hpwa'] ?? ''
      donation.customer_name = customerResp.data?.at(0)?.customer_values['nama-lengkap'] ?? ''
      donation.customer_nid = customerResp.data?.at(0)?.customer_values['nid'] ?? ''
      donation.customer_profiling = customerResp.data?.at(0)?.customer_values['profiling'] ?? ''

      donation.currency_name = ''
    }
    set({restDonations: donations, loadingRestDonations: false})
  },

  exportLoading: false,
  getRestDonationAff: async (
    page: number,
    companyIds: string[],
    affCode: string,
    currentCommunity: string,
    startDate: Date,
    endDate: Date,
    isExport: boolean
  ) => {
    set({loadingRestDonations: true, restDonations: []})

    const start = (page - 1) * 10 // Calculate the start index
    const end = start + 10 - 1 // Calculate the end index

    const filter = postgrest
      .from('donations')
      .select(
        `
          *,
          payments (id, name, description),
          programs (id, title, program_type)
        `,
        {
          count: 'exact',
        }
      )
      .in('company_id', companyIds)
      .is('deleted_at', 'NULL')

    if (affCode.length === 2) {
      filter.like('affiliate_code', `${affCode}__${currentCommunity}%`)
    }
    if (affCode.length === 4) {
      filter.like('affiliate_code', `${affCode}${currentCommunity}%`)
    }

    if (currentCommunity === '1') {
      filter.not('affiliate_code', 'like', `${affCode}__5%`)
    }

    filter
      .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
      .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
      .order('transfer_date', {ascending: false})

    if (!isExport) {
      filter.range(start, end)
    }

    const responses = await filter

    const totalPages = Math.ceil(responses.count / 10)
    set({
      restDonationModel: {
        ...(responses as RestDonationInterface),
        total_pages: totalPages,
      },
    })

    const donations = responses.data as DonationData[]
    for (const donation of donations) {
      const companyResp = await authPostgrest
        .from('companies')
        .select('id, name')
        .eq('id', donation.company_id)
        .limit(1)
        .single()
      donation.owner = companyResp.data?.name ?? ''

      const channelResp = await crmPostgrest
        .from('prospect_channels')
        .select('id, name')
        .eq('id', donation.channel_id)
        .limit(1)
      donation.channel_name = channelResp.data?.at(0)?.name ?? ''

      const customerResp = await crmPostgrest
        .from('customers')
        .select('id, customer_values')
        .eq('id', donation.customer_id)
        .limit(1)
      donation.customer_values = customerResp.data?.at(0)?.customer_values
      donation.customer_email = customerResp.data?.at(0)?.customer_values['email'] ?? ''
      donation.customer_phone = customerResp.data?.at(0)?.customer_values['nomor-hpwa'] ?? ''
      donation.customer_name = customerResp.data?.at(0)?.customer_values['nama-lengkap'] ?? ''
      donation.customer_nid = customerResp.data?.at(0)?.customer_values['nid'] ?? ''
      donation.customer_profiling = customerResp.data?.at(0)?.customer_values['profiling'] ?? ''

      donation.currency_name = ''

      const inputString: string = donation.affiliate_code
      const resultArray: string[] = splitStringByTwo(inputString)

      if (resultArray.length > 1) {
        const provinceResp = await authPostgrest
          .from('provinces')
          .select('id, name')
          .eq('acronym', resultArray[0])
          .limit(1)
          .single()

        const cityResp = await authPostgrest
          .from('cities')
          .select('id, name')
          .eq('province_id', provinceResp.data?.id)
          .eq('acronym', resultArray[1])
          .limit(1)
          .single()

        donation.province_name = provinceResp?.data?.name
        donation.city_name = cityResp?.data?.name
      }
    }

    if (isExport) {
      const workbook = XLSX.utils.book_new()

      interface exportData {
        invoice_code?: string
        donor_name?: string
        donor_email?: string
        donor_phone?: string
        program_name?: string
        amount?: number
        unique_code?: number
        affiliate_code?: string
        province_name?: string
        city_name?: string
        transfer_date?: string
        created_at?: string
        status?: string
      }
      const exportDatas: exportData[] = []

      for (const item of donations) {
        set({exportLoading: true})
        let transferDate = '-'
        if (item.status === 'settlement') {
          transferDate = timeDateFormatter(Date.parse(item.updated_at?.toString()))
        }
        exportDatas.push({
          invoice_code: item.invoice_code,
          donor_name: item.donor_name,
          donor_email: item.donor_email,
          donor_phone: item.donor_phone,
          program_name: item.programs?.title,
          amount: item.amount,
          unique_code: item.unique_code,
          affiliate_code: item.affiliate_code,
          province_name: item.province_name,
          city_name: item.city_name,
          transfer_date: transferDate,
          created_at: timeDateFormatter(Date.parse(item.created_at?.toString())),
          status: item.status,
        })
      }
      // 2. Convert JSON data to worksheet
      // XLSX.utils.json_to_sheet(data, opts);
      const worksheet = XLSX.utils.json_to_sheet(exportDatas)

      // 3. Add worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1') // "Sheet1" is the worksheet name.  Change if needed.

      // 4. Generate Excel file (XLSX format is generally preferred)
      // XLSX.write(workbook, opts);
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

      // 5. Save the file using FileSaver.js
      // fileSaver.saveAs(blob, filename, dont_auto_bom);
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })
      set({exportLoading: false})
      saveAs(data, 'data.xlsx')
    }

    set({restDonations: donations, loadingRestDonations: false})
  },
}))

function splitStringByTwo(str: string | null | undefined): string[] {
  if (!str) {
    return [] // Handle empty, null, or undefined string case
  }

  const result: string[] = []
  for (let i = 0; i < str.length; i += 2) {
    if (i + 1 < str.length) {
      result.push(str.substring(i, i + 2)) // Extract two characters
    } else {
      result.push(str[i]) // Handle the last character if the string has an odd length
    }
  }
  return result
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const createDonationSchema = z.object({
  company_id: z.string(),
  customer_type: z.string(),
  customer_id: z.string(),
  transfer_date: z.date(),
  program_id: z.string(),
  payment_id: z.string(),
  status: z.string(),
  amount: z.string(),
  channel_id: z.string(),
  depositor: z.string(),
  proof_of_payment: z.string(),
  fundraiser_id: z.string(),
  affiliate_code: z.string().optional(),
  remarks: z.string(),
})

export type CreateDonationFormValues = z.infer<typeof createDonationSchema>

export interface DonationFormState {
  field?: DonationData
  setField?: (item: DonationData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  setFormSelectDataById?: (id: string, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
}

export const useDonationFormStore = create<DonationFormState>((set, get) => ({
  field: undefined,
  setField: (item: DonationData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'customer_type',
        title: 'Tipe Customer',
        placeholder: 'Tipe Customer...',
        type: 'select',
        name: 'customer_type',
        value: get().field?.customer_type ?? '',
        selectData: [
          {label: 'Retail', value: 'customers'},
          {label: 'Corporate', value: 'customers_corporate'},
          {label: 'Community', value: 'customers_community'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'customer_id',
        title: 'Donatur',
        placeholder: 'Donatur...',
        type: 'select-with-text',
        name: 'customer_id',
        value: {
          label: `${get().field?.customer_name ?? '-'} - ${get().field?.customer_nid ?? '-'}`,
          value: get().field?.customer_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'transfer_date',
        title: 'Waktu Transfer',
        placeholder: 'Waktu Transfer...',
        type: 'datetime-blast',
        name: 'transfer_date',
        value: moment(get().field?.transfer_date).toDate() ?? moment(Date.now()).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'program_id',
        title: 'Program',
        placeholder: 'Program...',
        type: 'select-with-text',
        name: 'program_id',
        value: {
          label: get().field?.program_title ?? '-',
          value: get().field?.program_id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'payment_id',
        title: 'Payment Method',
        placeholder: 'Payment Method...',
        type: 'select-with-text',
        name: 'payment_id',
        value: {
          label: `${get().field?.payment_name ?? '-'} ${get().field?.payment_description ?? '-'}`,
          value: get().field?.payment_id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'status',
        title: 'Status',
        placeholder: 'Status...',
        type: 'select',
        name: 'status',
        value: get().field?.status ?? 'waiting',
        selectData: [
          {label: 'capture', value: 'capture'},
          {label: 'settlement', value: 'settlement'},
          {label: 'pending', value: 'pending'},
          {label: 'deny', value: 'deny'},
          {label: 'cancel', value: 'cancel'},
          {label: 'expire', value: 'expire'},
          {label: 'refund', value: 'Refund'},
          {label: 'partial_refund', value: 'partial_refund'},
          {label: 'authorize', value: 'authorize'},
          {label: 'waiting', value: 'waiting'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
        hidden: false,
      },
      {
        id: 'amount',
        title: 'Amount',
        placeholder: 'Amount...',
        type: 'currency',
        name: 'amount',
        value: get().field?.amount ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'channel_id',
        title: 'Channel',
        placeholder: 'Channel...',
        type: 'select-with-text',
        name: 'channel_id',
        value: {
          label: get().field?.channel_name,
          value: get().field?.channel_id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'depositor',
        title: 'Petugas Penerima',
        placeholder: 'Petugas Penerima...',
        type: 'text',
        name: 'depositor',
        value: get().field?.depositor ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
        hidden: false,
      },
      {
        id: 'proof_of_payment',
        title: 'Bukti Transfer',
        placeholder: 'Upload Media (Max file size 2Mb)',
        type: 'file-upload-v2',
        name: 'proof_of_payment',
        value: get().field?.proof_of_payment ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
        required: false,
      },
      {
        id: 'fundraiser_id',
        title: 'Fundraiser',
        placeholder: 'Fundraiser...',
        type: 'select-with-text',
        name: 'fundraiser_id',
        selectData: [],
        value: {
          label: `${get().field?.aff_code ?? ''} - ${get().field?.aff_name ?? ''}`,
          value: get().field?.aff_id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
      },
      {
        id: 'affiliate_code',
        title: 'Affiliate Code',
        placeholder: 'Affiliate Code...',
        type: 'text',
        name: 'affiliate_code',
        value: get().field?.affiliate_code ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
        hidden: false,
      },
      {
        id: 'remarks',
        title: 'Note (Catatan CRM)',
        placeholder: 'Note (Catatan CRM)...',
        type: 'text-area',
        name: 'remarks',
        value: get().field?.remarks ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
      },
    ]

    // if (formParam?.companyId === '468f9204-82ac-4ea7-a60c-0bd1e81eb636') {
    //   forms.push({
    //     id: 'currency_id',
    //     title: 'Currency',
    //     placeholder: 'Currency...',
    //     type: 'select-with-text',
    //     name: 'currency_id',
    //     selectData: [],
    //     value: {
    //       label: get().field?.currency_name,
    //       value: get().field?.currency_id,
    //     },
    //     disabled: formParam.action === FormAction.VIEW ? true : false,
    //     required: true,
    //   })
    // }

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    let form = {}

    // check required field
    let requiredError = false
    for (const item of get().forms) {
      if (
        item.required === true &&
        item.type.includes('select-with-text') &&
        (item.value?.value === '' || item.value?.value === undefined)
      ) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
      if (item.required === true && (item.value === '' || item.value === undefined)) {
        toast.error(`${item.title} is required ${item.value}`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    form = {
      ...form,
      // customer_type: reference,
      // status: 'waiting',
    }

    let res: DonationSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitDonation(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateDonation(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
  setFormSelectDataById: (id: string, selectData: SelectData[]) => {
    const forms = [...get().forms]
    const findIndex = forms.findIndex((el) => el.id === id)
    if (findIndex !== -1) {
      forms[findIndex] = {
        ...forms[findIndex],
        selectData: selectData,
      }
      set({forms: forms})
    }
  },
}))
