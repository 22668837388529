/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Email/ No Hp/ Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginCreative() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        const { data: user } = await getUserByToken(auth.data.accessToken)
        setCurrentUser(user)
      } catch (error) {
        saveAuth(undefined)
        setStatus(error?.response?.data?.message ?? 'Something went wrong')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      noValidate={true}
      id='kt_sign_in_form'
      onSubmit={formik.handleSubmit}
    >
      {/*begin::Heading*/}
      <div className='text-center mb-11'>
        {/*begin::Title*/}
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/*end::Title*/}
        {/*begin::Subtitle*/}
        <div className='text-gray-500 fw-semibold fs-6'>Welcome to {process.env.REACT_APP_THEME_NAME} CRM Platform</div>
        {/*end::Subtitle=*/}
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/*begin::Heading*/}
      {/*begin::Login options*/}
      {/* <div className="row g-3 mb-9"> */}
      {/*begin::Col*/}
      {/* <div className="col-md-6"> */}
      {/*begin::Google link=*/}
      {/* <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"> */}
      {/* <img alt="Logo" src={`${toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}`} className="h-15px me-3" /> */}
      {/* Sign in with Google */}
      {/* </a> */}
      {/*end::Google link=*/}
      {/* </div> */}
      {/*end::Col*/}
      {/*begin::Col*/}
      {/* <div className="col-md-6"> */}
      {/*begin::Google link=*/}
      {/* <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"> */}
      {/* <img alt="Logo" src={`${toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}`} className="theme-light-show h-15px me-3" /> */}
      {/* <img alt="Logo" src={`${toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}`} className="theme-dark-show h-15px me-3" />Sign in with Apple</a> */}
      {/*end::Google link=*/}
      {/* </div> */}
      {/*end::Col*/}
      {/* </div> */}
      {/*end::Login options*/}
      {/*begin::Separator*/}
      {/* <div className="separator separator-content my-14"> */}
      {/* <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span> */}
      {/* </div> */}
      {/*end::Separator*/}
      {/*begin::Input group=*/}
      <div className='fv-row mb-8'>
        {/*begin::Email*/}
        <input
          type='text'
          placeholder='Email / No Hp / Username'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {/*end::Email*/}
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/*end::Input group=*/}
      <div className='fv-row mb-3'>
        {/*begin::Password*/}
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {/*end::Password*/}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/*end::Input group=*/}
      {/*begin::Wrapper*/}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/*begin::Link*/}
        {/* <a href="../../demo2/dist/authentication/layouts/creative/reset-password.html" className="link-primary">Forgot Password ?</a> */}
        {/*end::Link*/}
      </div>
      {/*end::Wrapper*/}
      {/*begin::Submit button*/}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {/*begin::Indicator label*/}
          {!loading && <span className='indicator-label'>Sign In</span>}
          {/*end::Indicator label*/}
          {/*begin::Indicator progress*/}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          {/*end::Indicator progress*/}
        </button>
      </div>
      {/*end::Submit button*/}
      {/*begin::Sign up*/}
      {/* <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet? */}
      {/* <a href="../../demo2/dist/authentication/layouts/creative/sign-up.html" className="link-primary">Sign up</a> */}
      {/* </div> */}
      {/*end::Sign up*/}
    </form>
  )
}
