import { useEffect } from "react";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { KTCard, KTSVG } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";
import { useCsrPartnerFormStore, useCsrPartnerStore } from "../states/csr-partner.state";
import { FormAction } from "../../../../interfaces/form-action-interfaces";
import { LoadingComponent } from "../../crm/properties-management/components/loading.component";
import { generateImageUrl } from "../../../../../_metronic/helpers/generate-image-url";
import { timeDateFormatter } from "../../../../../_metronic/helpers/datetime-formatter";
import CsrPartnerModalComponent from "./csr-partner-modal.component";

const csrPartnerBreadcrumbs: Array<PageLink> = [
  {
    title: 'CSR Partners',
    path: '/apps/csr-management/csr-partner',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CsrPartnerScreen = () => {

  const { currentUser } = useAuth();
  const { items, getCsrPartner, csrPartnerLoading, onDelete } = useCsrPartnerStore();
  const { setField, generateForms, field, submitDone, formLoading } = useCsrPartnerFormStore();

  useEffect(() => {
    if (currentUser) {
      getCsrPartner(currentUser?.data?.company?.id)
    }
  }, [currentUser])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getCsrPartner(currentUser?.data?.company?.id)
    }
  }, [submitDone, formLoading])

  return (
    <>
      <PageTitle breadcrumbs={csrPartnerBreadcrumbs}>
        CSR Partners
      </PageTitle>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>CSR Partners</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>CSR Partners Management</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'CSR Partners',
                  subtitle: 'CSR Partners',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create CSR Partners
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {csrPartnerLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Logo Perusahaan</th>
                  <th>Nama Perusahaan</th>
                  <th>Website Perusahaan</th>
                  <th>Dibuat</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {items?.length === 0 ? (
                  <tr>
                    <td colSpan={8}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={generateImageUrl(item.company_logo)}
                            alt={item.id}
                            style={{
                              borderRadius: 10,
                              width: 150,
                              height: 75,
                              objectFit: 'cover',
                            }}
                          />
                        </td>
                        <td>{item.company_name}</td>
                        <td>{item.company_url}</td>
                        <td>{timeDateFormatter(Date.parse(item?.created_at?.toString()))}</td>
                        <td className='text-end'>
                          <a
                            href={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setField(item)
                              generateForms({
                                title: 'Update CSR Partners',
                                subtitle: 'Update CSR Partners',
                                action: FormAction.UPDATE,
                                data: item,
                                id: item.id,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, currentUser?.data?.company?.id)
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
      {field !== undefined && <CsrPartnerModalComponent />}
    </>
  );
}

export default CsrPartnerScreen;