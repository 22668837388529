import { useEffect } from "react";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { postgrest } from "../../../../api/postgrest";
import { KTCard, KTSVG } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";
import { useMasterCsrFormStore, useMasterCsrStore } from "../states/master-csr.state";
import { FormAction } from "../../../../interfaces/form-action-interfaces";
import { LoadingComponent } from "../../crm/properties-management/components/loading.component";
import { generateImageUrl } from "../../../../../_metronic/helpers/generate-image-url";
import MasterCsrModalComponent from "./master-csr-modal.component";

const masterCsrBreadcrumbs: Array<PageLink> = [
  {
    title: 'Master CSR',
    path: '/apps/csr-management/master-csr',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MasterCsrScreen = () => {

  const { currentUser } = useAuth();
  const { items, getMasterCsr, masterCsrLoading } = useMasterCsrStore();
  const { setField, generateForms, field, submitDone, formLoading } = useMasterCsrFormStore();

  useEffect(() => {
    if (currentUser) {
      getMasterCsr(currentUser?.data?.company?.id)
    }
  }, [currentUser])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getMasterCsr(currentUser?.data?.company?.id)
    }
  }, [submitDone, formLoading])

  return (
    <>
      <PageTitle breadcrumbs={masterCsrBreadcrumbs}>
        Master CSR
      </PageTitle>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Master CSR</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Master CSR Management</span>
          </h3>
          <div className='card-toolbar'>
            {items?.length === 0 && (
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  setField(null)
                  generateForms({
                    title: 'Master CSR',
                    subtitle: 'Master CSR',
                    action: FormAction.CREATE,
                  })
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Create Master Csr
              </button>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {masterCsrLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Thumbnail</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {items?.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  items?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={generateImageUrl(item.thumbnail)}
                            alt={item.id}
                            style={{
                              borderRadius: 10,
                              width: 150,
                              height: 75,
                              objectFit: 'cover',
                            }}
                          />
                        </td>
                        <td className='text-end'>
                          <a
                            href={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setField(item)
                              generateForms({
                                title: 'Update Master CSR',
                                subtitle: 'Update Master CSR',
                                action: FormAction.UPDATE,
                                data: item,
                                id: item.id,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          {/* <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, {
                                page: 0,
                                size: 10,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a> */}
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
      {field !== undefined && <MasterCsrModalComponent />}
    </>
  );
}

export default MasterCsrScreen;