import {IgnorePlugin} from 'webpack'
import {
  dateTimeISOFormatterEndDate,
  dateTimeISOFormatterStartDate,
} from '../../../../../../_metronic/helpers/datetime-formatter'
import {authPostgrest, postgrest} from '../../../../../api/postgrest'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {
  AffiliatorData,
  AffiliatorRestInterface,
} from '../../affiliator-management/interfaces/affilator-result.interface'
import {ProvinceData} from '../../provinces-management/interfaces/province-result.interface'
import {
  CityData,
  CityRestResultInterface,
  CityResultInterface,
} from '../interfaces/city-result.interface'
import {GetAllCity, GetAllCityParam} from '../providers/city.provider'
import {create} from 'zustand'

interface CityState {
  cityLoading: boolean
  cityItems: CityData[]
  citySelectData: SelectData[]
  citySelectDataDonation: SelectData[]
  cityModel?: CityResultInterface
  getCities: (params: GetAllCityParam) => void
  onDelete?: (id: string, params: GetAllCityParam) => void
  reset?: () => void

  getCitiesRest: (
    companyId: string,
    province: ProvinceData,
    startDate: Date,
    endDate: Date
  ) => Promise<void>
  getCitiesRestAdm: (
    companyId: string,
    province: ProvinceData,
    startDate: Date,
    endDate: Date,
    currentCommunity: string
  ) => Promise<void>
  getCurrentCitiesRest: (
    affCode: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => Promise<void>
  totalCityDonates: number
  totalCityDonors: number

  getVolunteerCitiesRest: (
    selectedProvince: ProvinceData,
    selectedCity: CityData,
    currentCommunity: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => Promise<void>
  affiliateItems: AffiliatorData[]
  selectedCity: CityData | null
  selectedProvince: ProvinceData | null
  totalVolunteerCity: number
  currentCommunity: string
}

export const useCityStore = create<CityState>((set, get) => ({
  reset: () => {
    set({
      citySelectData: [],
      cityModel: undefined,
      cityItems: [],
      citySelectDataDonation: [],
    })
  },
  selectedProvince: null,
  cityLoading: false,
  cityItems: [],
  citySelectData: [],
  citySelectDataDonation: [],
  cityModel: undefined,
  getCities: async (params: GetAllCityParam) => {
    set({cityLoading: true, citySelectData: [], citySelectDataDonation: [], cityItems: []})
    const response = await GetAllCity(params)
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        let transformDatasDonation: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
          transformDatasDonation.push({
            label: item.name,
            value: item.acronym,
          })
        }
        set({
          citySelectData: transformDatas,
          citySelectDataDonation: transformDatasDonation,
          cityItems: response.data.items,
        })
      } else {
        set({
          citySelectData: [],
          citySelectDataDonation: [],
          cityItems: [],
        })
      }
    } else {
      set({
        citySelectData: [],
        citySelectDataDonation: [],
        cityItems: [],
      })
    }
    set({cityLoading: false})
  },
  onDelete: (id: string, params: GetAllCityParam) => {},
  totalCityDonors: 0,
  totalCityDonates: 0,
  getCitiesRest: async (
    companyId: string,
    province: ProvinceData,
    startDate: Date,
    endDate: Date
  ) => {
    set({cityLoading: true, cityItems: [], totalCityDonates: 0, totalCityDonors: 0})
    const cityResponses = await authPostgrest
      .from('cities')
      .select('*', {count: 'exact'})
      .eq('province_id', province?.id)
      .order('name', {ascending: true})
    const cityModel = cityResponses as CityRestResultInterface
    const cities: CityData[] = []
    let totalDonates = 0
    let totalDonors = 0
    for (const city of cityModel?.data) {
      const respDonations = await postgrest
        .from('donations')
        .select('id, affiliate_code, amount', {count: 'exact'})
        .eq('status', 'settlement')
        .eq('company_id', companyId)
        .like('affiliate_code', `${province.acronym}${city.acronym}%`)
        .is('deleted_at', 'NULL')
        .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
        .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
        .order('created_at', {ascending: false})

      // get community P
      let totalDonateCommunityP = 0
      let totalDonateCommunityS = 0
      let totalDonateCommunityU = 0
      for (const donate of respDonations.data) {
        const community = (donate.affiliate_code as string).charAt(4)
        if (community === '1') {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '5') {
          totalDonateCommunityS += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '3') {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        }
      }
      cities.push({
        ...city,
        total_donate_p: totalDonateCommunityP,
        total_donate_s: totalDonateCommunityS,
        total_donate_u: totalDonateCommunityU,
      })
    }
    set({
      cityItems: cities,
      cityLoading: false,
      totalCityDonates: totalDonates,
      totalCityDonors: totalDonors,
    })
  },
  selectedCity: null,
  getCitiesRestAdm: async (
    companyId: string,
    province: ProvinceData,
    startDate: Date,
    endDate: Date,
    currentCommunity: string
  ) => {
    set({cityLoading: true, cityItems: [], totalCityDonates: 0, totalCityDonors: 0})
    const cityResponses = await authPostgrest
      .from('cities')
      .select('*', {count: 'exact'})
      .eq('province_id', province?.id)
      .order('name', {ascending: true})
    const cityModel = cityResponses as CityRestResultInterface
    const cities: CityData[] = []
    let totalDonates = 0
    let totalDonors = 0
    for (const city of cityModel?.data) {
      const respDonations = await postgrest
        .from('donations')
        .select('id, affiliate_code, amount', {count: 'exact'})
        .eq('status', 'settlement')
        .eq('company_id', companyId)
        .like('affiliate_code', `${province.acronym}${city.acronym}%`)
        .is('deleted_at', 'NULL')
        .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
        .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
        .order('created_at', {ascending: false})

      // get community P
      let totalDonateCommunityP = 0
      let totalDonateCommunityS = 0
      let totalDonateCommunityU = 0
      for (const donate of respDonations.data) {
        const community = (donate.affiliate_code as string).charAt(4)
        if (community === '1' && currentCommunity === '1') {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '5' && currentCommunity === '5') {
          totalDonateCommunityS += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '3' && currentCommunity === '3') {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (
          currentCommunity !== '1' &&
          currentCommunity !== '5' &&
          currentCommunity !== '3'
        ) {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        }
      }
      cities.push({
        ...city,
        total_donate_p: totalDonateCommunityP,
        total_donate_s: totalDonateCommunityS,
        total_donate_u: totalDonateCommunityU,
      })
    }
    set({
      cityItems: cities,
      cityLoading: false,
      totalCityDonates: totalDonates,
      totalCityDonors: totalDonors,
    })
  },

  getVolunteerCitiesRest: async (
    selectedProvince: ProvinceData,
    selectedCity: CityData,
    currentCommunity: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => {
    set({
      cityLoading: true,
      totalCityDonates: 0,
      totalCityDonors: 0,
      affiliateItems: [],
    })

    const responses = await authPostgrest
      .from('users_with_relations')
      .select(
        `
            id,
            affiliate_code
          `
      )
      .is('deleted_at', 'NULL')
      .eq('company_id', companyId)
      .like('affiliate_code', `${selectedProvince?.acronym}${selectedCity.acronym}%`)
      .eq('role_name', 'Relawan')

    const respAffiliates = responses as AffiliatorRestInterface

    const affiliates: AffiliatorData[] = []
    let totalDonates = 0
    let totalDonors = 0
    const affCodes: string[] = []
    for (const data of respAffiliates.data) {
      const community = (data.affiliate_code as string).charAt(4)
      if (community === '1' && currentCommunity === '1') {
        affCodes.push(data.affiliate_code)
        affiliates.push(data)
      } else if (community === '3' && currentCommunity === '3') {
        affCodes.push(data.affiliate_code)
        affiliates.push(data)
      } else if (community === '5' && currentCommunity === '5') {
        affCodes.push(data.affiliate_code)
        affiliates.push(data)
      } else if (community !== '1' && community !== '5' && community !== '3') {
        affCodes.push(data.affiliate_code)
        affiliates.push(data)
      }
    }
    const payload = {
      data: {
        status: 'settlement',
        company_id: 'ab7ae967-ad36-4874-97ab-365fff019bb5',
        affiliate_codes: affCodes,
        transfer_date_start: dateTimeISOFormatterStartDate(startDate),
        transfer_date_end: dateTimeISOFormatterEndDate(endDate),
      },
    }
    const respDonations = await postgrest.rpc('get_donations', payload)
    const newAffiliates: AffiliatorData[] = []
    for (const item of affiliates) {
      const dataDonate = respDonations.data.find(
        (value) => value.affiliate_code === item.affiliate_code
      )
      if (dataDonate) {
        if (dataDonate.affiliate_code === item.affiliate_code) {
          newAffiliates.push({
            ...item,
            total_donations: dataDonate.amount_sum,
          })
          totalDonates += dataDonate.amount_sum
          totalDonors += 1
        }
      } else {
        newAffiliates.push({
          ...item,
          total_donations: 0,
        })
      }
    }

    const sortedData = newAffiliates.sort((a, b) => {
      const totalA = a.total_donations || 0
      const totalB = b.total_donations || 0
      return totalB - totalA
    })

    set({
      affiliateItems: sortedData,
      cityLoading: false,
      totalCityDonates: totalDonates,
      totalCityDonors: totalDonors,
    })
  },
  affiliateItems: [],

  currentCommunity: '',
  totalVolunteerCity: 0,
  getCurrentCitiesRest: async (
    affCode: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => {
    set({
      cityItems: [],
      cityLoading: true,
      totalCityDonates: 0,
      totalCityDonors: 0,
      selectedCity: null,
      totalVolunteerCity: 0,
      selectedProvince: null,
      currentCommunity: '',
    })

    const removeAdm = affCode.replaceAll('ADM', '')
    const currentCommunity = removeAdm.charAt(removeAdm.length - 1)
    const parseAcronym = removeAdm.slice(0, removeAdm.length - 1)
    const provinceAcronym = `${parseAcronym.charAt(0)}${parseAcronym.charAt(1)}`
    const cityAcronym = `${parseAcronym.charAt(2)}${parseAcronym.charAt(3)}`

    const provinceResponse = await authPostgrest
      .from('provinces')
      .select('*', {count: 'exact'})
      .eq('acronym', provinceAcronym)
      .limit(1)

    const cityResponses = await authPostgrest
      .from('cities')
      .select('*', {count: 'exact'})
      .eq('province_id', provinceResponse.data.at(0).id)
      .eq('acronym', cityAcronym)
      .limit(1)

    const cityModel = cityResponses as CityRestResultInterface
    const cities: CityData[] = []
    let totalDonates = 0
    let totalDonors = 0
    for (const city of cityModel?.data) {
      const respDonations = await postgrest
        .from('donations')
        .select('id, affiliate_code, amount, status, created_at', {count: 'exact'})
        .eq('status', 'settlement')
        .eq('company_id', companyId)
        .like('affiliate_code', `${parseAcronym}%`)
        .is('deleted_at', 'NULL')
        .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
        .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
        .order('created_at', {ascending: false})

      // get community P
      let totalDonateCommunityP = 0
      let totalDonateCommunityS = 0
      let totalDonateCommunityU = 0
      let index = 0
      for (const donate of respDonations.data) {
        const community = (donate.affiliate_code as string).charAt(4)
        if (community === '1' && currentCommunity === '1') {
          console.log(
            index,
            donate.id,
            donate.amount,
            donate.affiliate_code,
            donate.status,
            donate.created_at
          )
          index++
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '5' && currentCommunity === '5') {
          totalDonateCommunityS += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '3' && currentCommunity === '3') {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (
          currentCommunity !== '1' &&
          currentCommunity !== '5' &&
          currentCommunity !== '3'
        ) {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        }
      }
      cities.push({
        ...city,
        total_donate_p: totalDonateCommunityP,
        total_donate_s: totalDonateCommunityS,
        total_donate_u: totalDonateCommunityU,
      })
    }

    // get total volunteers
    const responses = await authPostgrest
      .from('users_with_relations')
      .select(
        `
            id, affiliate_code
          `
      )
      .is('deleted_at', 'NULL')
      .eq('company_id', companyId)
      .like('affiliate_code', `${parseAcronym}%`)
      .eq('role_name', 'Relawan')

    let totalVolunteerCommunityP = 0
    let totalVolunteerCommunityS = 0
    let totalVolunteerCommunityU = 0
    for (const data of responses.data) {
      const community = (data.affiliate_code as string).charAt(4)
      if (community === '1') {
        totalVolunteerCommunityP += 1
      } else if (community === '5') {
        totalVolunteerCommunityS += 1
      } else if (community === '3') {
        totalVolunteerCommunityU += 1
      } else {
        totalVolunteerCommunityP += 1
      }
    }
    if (currentCommunity === '1') {
      set({totalVolunteerCity: totalVolunteerCommunityP})
    } else if (currentCommunity === '5') {
      set({totalVolunteerCity: totalVolunteerCommunityS})
    } else if (currentCommunity === '3') {
      set({totalVolunteerCity: totalVolunteerCommunityU})
    } else {
      set({totalVolunteerCity: totalVolunteerCommunityP})
    }
    console.log('cities: ', cities)
    set({
      cityItems: cities,
      cityLoading: false,
      selectedCity: cities.at(0),
      selectedProvince: provinceResponse.data.at(0),
      totalCityDonates: totalDonates,
      totalCityDonors: totalDonors,
      currentCommunity: currentCommunity,
    })
  },
}))
