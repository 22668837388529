import {
  dateTimeISOFormatterEndDate,
  dateTimeISOFormatterStartDate,
} from '../../../../../../_metronic/helpers/datetime-formatter'
import {authPostgrest, postgrest} from '../../../../../api/postgrest'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {
  ProvinceData,
  ProvinceRestResultInterface,
  ProvinceResultInterface,
} from '../interfaces/province-result.interface'
import {GetAllProvince, GetAllProvinceParam} from '../providers/province.provider'
import {create} from 'zustand'

interface ProvinceState {
  provinceLoading: boolean
  provinceItems: ProvinceData[]
  provinceSelectData: SelectData[]
  provinceSelectDataDonation: SelectData[]
  provinceModel?: ProvinceResultInterface
  getProvinces: (params: GetAllProvinceParam) => void
  onDelete?: (id: string, params: GetAllProvinceParam) => void
  reset?: () => void

  getProvincesRest: (companyId: string, startDate: Date, endDate: Date) => Promise<void>
  totalDonates: number
  totalDonors: number

  getCurrentProvincesRest: (
    affCode: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => Promise<void>
  currentCommunity: string | null
  selectedProvince: ProvinceData | null
}

export const useProvinceStore = create<ProvinceState>((set, get) => ({
  reset: () => {
    set({
      provinceSelectData: [],
      provinceModel: undefined,
      provinceItems: [],
      provinceSelectDataDonation: [],
    })
  },
  provinceLoading: false,
  provinceItems: [],
  provinceSelectData: [],
  provinceSelectDataDonation: [],
  provinceModel: undefined,
  totalDonates: 0,
  totalDonors: 0,
  selectedProvince: null,
  currentCommunity: null,
  getCurrentProvincesRest: async (
    affCode: string,
    companyId: string,
    startDate: Date,
    endDate: Date
  ) => {
    set({
      provinceLoading: true,
      provinceItems: [],
      selectedProvince: null,
      totalDonates: 0,
      totalDonors: 0,
    })

    const removeAdm = affCode.replaceAll('ADM', '')
    const currentCommunity = removeAdm.charAt(removeAdm.length - 1)
    const parseAcronym = removeAdm.slice(0, removeAdm.length - 1)

    const responses = await authPostgrest
      .from('provinces')
      .select('*', {count: 'exact'})
      .eq('acronym', parseAcronym)
      .order('name', {ascending: true})
    const provinceModel = responses as ProvinceRestResultInterface

    const provinces: ProvinceData[] = []
    let totalDonates = 0
    let totalDonors = 0
    for (const province of provinceModel?.data) {
      const respDonations = await postgrest
        .from('donations')
        .select('id, affiliate_code, amount', {count: 'exact'})
        .eq('status', 'settlement')
        .eq('company_id', companyId)
        .like('affiliate_code', `${province.acronym}%`)
        .is('deleted_at', 'NULL')
        .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
        .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
        .order('created_at', {ascending: false})

      // get community P
      let totalDonateCommunityP = 0
      let totalDonateCommunityS = 0
      let totalDonateCommunityU = 0
      for (const donate of respDonations.data) {
        const community = (donate.affiliate_code as string).charAt(4)
        console.log(
          'community province',
          community,
          donate.amount,
          donate.affiliate_code,
          currentCommunity
        )
        if (community === '1' && currentCommunity === '1') {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '5' && currentCommunity === '5') {
          totalDonateCommunityS += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '3' && currentCommunity === '3') {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (
          currentCommunity !== '1' &&
          currentCommunity !== '5' &&
          currentCommunity !== '3'
        ) {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        }
      }
      provinces.push({
        ...province,
        total_donate_p: totalDonateCommunityP,
        total_donate_s: totalDonateCommunityS,
        total_donate_u: totalDonateCommunityU,
      })
    }

    set({
      currentCommunity: currentCommunity,
      provinceItems: provinces,
      selectedProvince: provinces.at(0),
      provinceLoading: false,
      totalDonates: totalDonates,
      totalDonors: totalDonors,
    })
  },
  getProvincesRest: async (companyId: string, startDate: Date, endDate: Date) => {
    set({
      provinceLoading: true,
      provinceItems: [],
    })
    const responses = await authPostgrest
      .from('provinces')
      .select('*', {count: 'exact'})
      .order('name', {ascending: true})
    const provinceModel = responses as ProvinceRestResultInterface

    const provinces: ProvinceData[] = []
    let totalDonates = 0
    let totalDonors = 0
    for (const province of provinceModel?.data) {
      const respDonations = await postgrest
        .from('donations')
        .select('id, affiliate_code, amount', {count: 'exact'})
        .eq('status', 'settlement')
        .eq('company_id', companyId)
        .like('affiliate_code', `${province.acronym}%`)
        .is('deleted_at', 'NULL')
        .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
        .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
        .order('created_at', {ascending: false})

      // get community P
      let totalDonateCommunityP = 0
      let totalDonateCommunityS = 0
      let totalDonateCommunityU = 0
      for (const donate of respDonations.data) {
        const community = (donate.affiliate_code as string).charAt(4)
        if (community === '1') {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '5') {
          totalDonateCommunityS += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else if (community === '3') {
          totalDonateCommunityU += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        } else {
          totalDonateCommunityP += donate.amount
          totalDonates += donate.amount
          totalDonors += 1
        }
      }
      provinces.push({
        ...province,
        total_donate_p: totalDonateCommunityP,
        total_donate_s: totalDonateCommunityS,
        total_donate_u: totalDonateCommunityU,
      })
    }

    set({
      provinceItems: provinces,
      provinceLoading: false,
      totalDonates: totalDonates,
      totalDonors: totalDonors,
    })
  },
  getProvinces: async (params: GetAllProvinceParam) => {
    set({
      provinceLoading: true,
      provinceSelectData: [],
      provinceItems: [],
      provinceSelectDataDonation: [],
    })
    const response = await GetAllProvince(params)
    if (response?.status) {
      if (response?.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        let transformDatasDonation: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
          transformDatasDonation.push({
            label: item.name,
            value: item.acronym,
          })
        }
        set({
          provinceSelectData: transformDatas,
          provinceSelectDataDonation: transformDatasDonation,
          provinceItems: response.data.items,
        })
      } else {
        set({
          provinceSelectData: [],
          provinceItems: [],
          provinceSelectDataDonation: [],
        })
      }
    } else {
      set({
        provinceSelectData: [],
        provinceItems: [],
        provinceSelectDataDonation: [],
      })
    }
    set({provinceLoading: false})
  },
  onDelete: (id: string, params: GetAllProvinceParam) => {},
}))
