import {create} from 'zustand'
import {MasterCsr, MasterCsrResult} from '../interfaces/master-csr-result.interface'
import {postgrest} from '../../../../api/postgrest'
import {FormAction, FormInput, FormProps} from '../../../../interfaces/form-action-interfaces'
import {generateFormLogic} from '../../../../../_metronic/helpers/generate-form-logic'
import {toast} from 'react-toastify'

interface MasterCsrState {
  masterCsrLoading: boolean
  items: MasterCsr[]
  getMasterCsr: (companyId: string) => Promise<void>
}

export const useMasterCsrStore = create<MasterCsrState>((set, get) => ({
  masterCsrLoading: true,
  items: [],
  getMasterCsr: async (companyId: string) => {
    set({masterCsrLoading: true})
    const response = await postgrest
      .from('master_csrs')
      .select('*')
      .eq('company_id', companyId)
      .is('deleted_at', null)
      .limit(1)
    const items = response as MasterCsrResult
    set({masterCsrLoading: false, items: items.data})
  },
}))

interface MasterCsrFormState {
  field?: MasterCsr
  setField?: (item: MasterCsr) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam: FormProps
  reset: () => void
  onSubmit: (companyId: string) => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useMasterCsrFormStore = create<MasterCsrFormState>((set, get) => ({
  field: undefined,
  setField: (item: MasterCsr) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms.push({
      id: 'thumbnail',
      title: 'Thumbnail',
      placeholder: 'Upload Media (Max file size 2Mb)',
      type: 'file-upload-v2',
      name: 'thumbnail',
      value: get().field?.thumbnail,
      disabled: formParam.action === FormAction.VIEW ? true : false,
    })

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (companyId: string) => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
        company_id: companyId,
      }
    }

    if (get().formParam.action === FormAction.CREATE) {
      const res = await postgrest.from('master_csrs').insert(form)
      if (res.status === 201) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    } else {
      const res = await postgrest.from('master_csrs').update(form).eq('id', get().formParam.id)
      if (res.status === 204) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    }
  },
}))
