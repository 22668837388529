import {create} from 'zustand'
import {postgrest} from '../../../../api/postgrest'
import {FormAction, FormInput, FormProps} from '../../../../interfaces/form-action-interfaces'
import {generateFormLogic} from '../../../../../_metronic/helpers/generate-form-logic'
import {toast} from 'react-toastify'
import {CsrPartner, CsrPartnerResult} from '../interfaces/csr-partner-result.interface'

interface CsrPartnerState {
  csrPartnerLoading: boolean
  items: CsrPartner[]
  getCsrPartner: (companyId: string) => Promise<void>
  onDelete: (id: string, companyId: string) => Promise<void>
}

export const useCsrPartnerStore = create<CsrPartnerState>((set, get) => ({
  csrPartnerLoading: true,
  items: [],
  getCsrPartner: async (companyId: string) => {
    set({csrPartnerLoading: true})
    const response = await postgrest
      .from('csr_partners')
      .select('*')
      .eq('company_id', companyId)
      .is('deleted_at', null)
    const items = response as CsrPartnerResult
    set({csrPartnerLoading: false, items: items.data})
  },
  onDelete: async (id: string, companyId: string) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({csrPartnerLoading: true})
      const response = await postgrest
        .from('csr_partners')
        .update({deleted_at: new Date()})
        .eq('id', id)
      if (response.status) {
        toast.success('Berhasil menghapus data')
      } else {
        toast.error('Gagal menghapus data')
      }
      get().getCsrPartner(companyId)
      set({csrPartnerLoading: false})
    }
  },
}))

interface CsrPartnerFormState {
  field?: CsrPartner
  setField?: (item: CsrPartner) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam: FormProps
  reset: () => void
  onSubmit: (companyId: string) => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useCsrPartnerFormStore = create<CsrPartnerFormState>((set, get) => ({
  field: undefined,
  setField: (item: CsrPartner) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms.push({
      id: 'company_logo',
      title: 'Logo Perusahaan',
      placeholder: 'Upload Media (Max file size 2Mb)',
      type: 'file-upload-v2',
      name: 'company_logo',
      value: get().field?.company_logo,
      disabled: formParam.action === FormAction.VIEW ? true : false,
    })
    forms.push({
      id: 'company_name',
      title: 'Nama Perusahaan',
      placeholder: 'Nama Perusahaan...',
      type: 'text',
      name: 'company_name',
      value: get().field?.company_name ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'company_url',
      title: 'Website Perusahaan',
      placeholder: 'Website Perusahaan...',
      type: 'text',
      name: 'company_url',
      value: get().field?.company_url ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (companyId: string) => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
        company_id: companyId,
        created_at: new Date(),
        updated_at: new Date(),
      }
    }

    if (get().formParam.action === FormAction.CREATE) {
      const res = await postgrest.from('csr_partners').insert(form)
      if (res.status === 201) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    } else {
      const res = await postgrest.from('csr_partners').update(form).eq('id', get().formParam.id)
      if (res.status === 204) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    }
  },
}))
