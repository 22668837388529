/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from '../../../../../app/modules/auth'
import SidebarMenuMainRelawan from './SidebarMenuMainRelawan'
import SidebarMenuMainKorca from './SidebarMenuMainKorca'
import SidebarMenuMainBendahara from './SidebarMenuMainBendahara'
import SidebarMenuMainAdmin from './SidebarMenuMainAdmin'
import SidebarMenuMainDireksi from './SidebarMenuMainDireksi'
import SidebarMenuMainAdminPlatform from './SideBarMenuMainAdminPlatform'
import SidebarMenuMainAdminBranch from './SidebarMenuMainAdminBranch'
import SidebarMenuMainAdminSubBranch from './SidebarMenuMainAdminSubbranch'
import SidebarMenuMainMitraLembaga from './SidebarMenuMainMitraLembaga'
import SidebarMenuMainLeadBranch from './SidebarMenuMainLeadBranch'
import SidebarMenuMainTaqur from './SideBarMenuMainTaqur'

const SidebarMenuMain = () => {
  const { currentUser } = useAuth()

  return (
    <>
      {currentUser?.data?.role?.slug === 'relawan' ? <SidebarMenuMainRelawan /> : null}
      {currentUser?.data?.role?.slug === 'korca' ? <SidebarMenuMainKorca /> : null}
      {currentUser?.data?.role?.slug === 'direksi' ? <SidebarMenuMainDireksi /> : null}
      {currentUser?.data?.role?.slug === 'bendahara' ? <SidebarMenuMainBendahara /> : null}
      {currentUser?.data?.role?.slug === 'superadmin' ? <SidebarMenuMainAdmin /> : null}
      {currentUser?.data?.role?.slug === 'admin-platform' ? <SidebarMenuMainAdminPlatform /> : null}
      {currentUser?.data?.role?.slug === 'admin-berita' ? <SidebarMenuMainAdminPlatform /> : null}
      {currentUser?.data?.role?.slug === 'admin' ? <SidebarMenuMainAdmin /> : null}
      {currentUser?.data?.role?.slug === 'admin-pusat' ? <SidebarMenuMainAdmin /> : null}
      {currentUser?.data?.role?.slug === 'admin-taqur' &&
        currentUser?.data?.company?.id === 'ab7ae967-ad36-4874-97ab-365fff019bb5' &&
        currentUser?.data?.role?.slug === 'admin-taqur' ? (
        <SidebarMenuMainTaqur />
      ) : null}
      {currentUser?.data?.role?.slug === 'admin-branch' &&
        currentUser?.data?.company?.id === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ? (
        <SidebarMenuMainLeadBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'admin-branch' &&
        currentUser?.data?.company?.id === 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
        <SidebarMenuMainLeadBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'admin-branch' &&
        currentUser?.data?.company?.id === 'ab7ae967-ad36-4874-97ab-365fff019bb5' ? (
        <SidebarMenuMainAdminBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'admin-subbranch' &&
        currentUser?.data?.company?.id === 'ab7ae967-ad36-4874-97ab-365fff019bb5' ? (
        <SidebarMenuMainAdminSubBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'mitra-lembaga' ? <SidebarMenuMainMitraLembaga /> : null}
      {currentUser?.data?.role?.slug === 'lead-branch' &&
        currentUser?.data?.company?.id === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ? (
        <SidebarMenuMainLeadBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'lead-branch' &&
        currentUser?.data?.company?.id === 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
        <SidebarMenuMainLeadBranch />
      ) : null}
    </>
  )
}

export { SidebarMenuMain }
