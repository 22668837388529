import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { KTCard, KTSVG } from '../../../../../../../_metronic/helpers'
import { FormAction } from '../../../../../../interfaces/form-action-interfaces'
import { LoadingComponent } from '../../../../crm/properties-management/components/loading.component'
import {
  useTaqurTransactionFormStore,
  useTaqurTransactionStore,
} from '../states/transaction-management.state'
import TransactionModalComponent from './transaction-management-modal.component'
import { useNavigate } from 'react-router-dom'
import { generateCurrencyFormat } from '../../../../../../helpers/generate-currency-format'
import {
  dateQueryFormatter,
  timeDateFormatter,
} from '../../../../../../../_metronic/helpers/datetime-formatter'
import { generateStatusBadge } from '../../../../../../../_metronic/helpers/generate-status-badge'
import { toast } from 'react-toastify'
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from '../../../../../auth'
import { useAuth } from '../../../../../auth'
import ImportTaqurTransactionModalComponent from './transaction-import-excel-modal.component'

const TransactionListComponent = () => {
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<Date>(undefined)
  const [startDate, setStartDate] = useState<Date>(undefined)

  const {
    getTransaction,
    transactionItems,
    transactionLoading,
    transactionModel,
    totalTransactionTaqur,
    onDelete,
  } = useTaqurTransactionStore()
  const { currentUser } = useAuth()
  const { setField, field, generateForms, open, setOpen, submitDone, formLoading, reset } =
    useTaqurTransactionFormStore()

  const navigate = useNavigate()

  useEffect(() => {
    getTransaction({
      page: 0,
      size: 10,
      reference: 'taqur_yakesma',
    })
    totalTransactionTaqur()
  }, [])

  useEffect(() => {
    getTransaction({
      page: 0,
      size: 10,
      start_date: startDate === undefined ? undefined : dateQueryFormatter(startDate),
      end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
      reference: 'taqur_yakesma',
    })
    totalTransactionTaqur()
    if (submitDone === true && formLoading === false) {
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Transaction</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Transaction Management</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary mr-3'
              onClick={(e) => {
                setOpen(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Import Excel
            </button>
            <button
              type='button'
              className='btn btn-primary mr-3'
              onClick={(e) => {
                e.preventDefault()
                if (!exportLoading) {
                  const tokenLocal = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
                  const token = JSON.parse(tokenLocal) as AuthModel
                  setExportLoading(true)
                  const params = {
                    company_id: currentUser?.data?.company?.id,
                    reference: 'taqur_yakesma',
                  }
                  fetch(
                    `${process.env.REACT_APP_API_PROGRAM_URL
                    }/export-donations?${new URLSearchParams(params)}`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type':
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        Authorization: `Bearer ${token?.data?.accessToken}`,
                      },
                    }
                  )
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(new Blob([blob]))

                      const link = document.createElement('a')
                      link.href = url
                      link.download = `Export_Donation_${Date.now()}.xlsx`

                      document.body.appendChild(link)

                      link.click()

                      link.parentNode.removeChild(link)
                      setExportLoading(false)
                    })
                    .catch((e) => {
                      setExportLoading(false)
                    })
                } else {
                  toast.error('Exporting in progress...')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Export Excel
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Create New Transaction',
                  subtitle: 'Create New Transaction',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New Transaction
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {transactionLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Nama Siswa</th>
                  <th>Program</th>
                  <th>Amount</th>
                  <th>Transfer Date</th>
                  <th>CreatedAt</th>
                  <th>Status</th>
                  <th>E-Kwitansi</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {transactionItems?.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  transactionItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {' '}
                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/crm/customer-management/customer/${item.customer_id}`)
                            }}
                          >
                            {item?.customer_values
                              ? item.customer_values['nama-lengkap'] ||
                              item.customer_values['nama-perusahaan'] ||
                              item.customer_values['nama-komunitas']
                              : '-'}
                          </span>
                          <div className='d-flex flex-column'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary mb-1'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate(
                                  `/apps/crm/customer-management/customer/${item.customer_id}`
                                )
                              }}
                            >
                              {/* {item.customer_name ?? '-'} */}
                            </a>
                            <span>
                              {item?.customer_values
                                ? item.customer_values['email'] ||
                                item.customer_values['email-pic'] ||
                                item.customer_values['email-komunitas']
                                : '-'}
                            </span>
                            <span>
                              {' '}
                              {item?.customer_values
                                ? item.customer_values['no-telp-komunitas'] ||
                                item.customer_values['nomor-hpwa'] ||
                                item.customer_values['nomor-hpwa-pic'] ||
                                item.customer_values['no-perusahaan']
                                : '-'}{' '}
                            </span>
                            <span>{item?.customer_values ? item.customer_values['nid'] : '-'}</span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span>{item.program_title}</span>
                            <span className='badge badge-success'>{item.program_type}</span>
                          </div>
                        </td>
                        <td>{generateCurrencyFormat(item.amount, item.currency_name)}</td>
                        <td>{timeDateFormatter(Date.parse(item?.transfer_date?.toString()))}</td>
                        <td>{timeDateFormatter(Date.parse(item?.created_at?.toString()))}</td>
                        <td>{generateStatusBadge(item.status)}</td>
                        <td>
                          <span
                            className='badge badge-success'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              if (
                                item.status === 'settlement' ||
                                item.status === 'capture'
                              ) {
                                navigate(
                                  `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                                )
                              } else {
                                toast.error('Tidak dapat membuka ekwitansi, transaksi belum settle')
                              }
                            }}
                          >
                            E-Kwitansi
                          </span>
                        </td>
                        <td className='text-end'>
                          <a
                            href={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setField(item)
                              generateForms({
                                title: 'Update Donation',
                                subtitle: 'Update Donation',
                                action: FormAction.UPDATE,
                                data: item,
                                id: item.id,
                                // companyId: currentUser?.data?.company?.id,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, {
                                page: 0,
                                size: 10,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={transactionModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {open === true && <ImportTaqurTransactionModalComponent />}
      {field !== undefined && <TransactionModalComponent />}
    </>
  )
}

const TransactionListWrapper = () => {
  return (
    <>
      <TransactionListComponent />
    </>
  )
}

export default TransactionListWrapper
