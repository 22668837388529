import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DonationByPeriodeResultInterface} from '../interfaces/donation-by-periode-result.interface'
import {DonationChannelInterface} from '../interfaces/donation-channel-result.interface'
import {DonationResultInterface, DonationData} from '../interfaces/donation-result.interface'
import {
  DonationSingleResultInterface,
  TotalDonationInterface,
} from '../interfaces/donation-single-result.interface'
import {DonationPlatformFee} from '../interfaces/donation-platform-fee.interface'

export interface GetAllDonationParam {
  page?: number
  size?: number
  reference?: string
  program_id?: string
  valid?: string
  status?: string
  is_distributed?: string
  customer_id?: string
  email?: string
  province_id?: string
  city_id?: string
  start_date?: string
  end_date?: string
  fundraiser_id?: string
  program_type?: string
  aff_code?: string
  branch_id?: string
  payment_id?: string
  payment_selected?: string
  channel_id?: string
  channel_selected?: string
  community_id?: string
  class_code?: string
  invoice_code?: string
  proof_of_payment?: string
  amount?: string
  unique_code?: string
}

export interface DonationDashboardParam {
  start_date?: string
  end_date?: string
  customer_id?: string
}

export interface TotalDonationKorcaParam {
  payment_type?: string
}

export const GetAllDonation = async (
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get('/donation-cached-v2', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const GetAllTransactionTaqur = async (
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get('/taqur', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const GetAllDonationByAffiliator = async (
  id: string,
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get('/donations-by-affiliate-code/' + id, {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const GetAllDonationByCustomer = async (
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get('/donations-customer', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const GetAllDonationByInternalMemo = async (
  internalMemoId: string,
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get(`/donations-by-internal-memo/${internalMemoId}`, {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const GetAllDonationByWorkOder = async (
  workOderId: string,
  params: GetAllDonationParam
): Promise<DonationResultInterface> => {
  try {
    let response = await ApiProgram.get(`/donations-by-work-order/${workOderId}`, {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationResultInterface = a.response?.data
    return response
  }
}

export const ShowDonation = async (id: string): Promise<DonationSingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/public/donations/' + id)
    return response.data as DonationSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDonation = async (
  form: DonationData
): Promise<DonationSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/donation', form)
    return response.data as DonationSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDonation = async (
  id: string,
  form: DonationData
): Promise<DonationSingleResultInterface> => {
  try {
    let response = await ApiProgram.put('/donation/' + id, form)
    return response.data as DonationSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDonation = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/donation/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonation = async (
  params: DonationDashboardParam
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donations', {
      params: params,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationTrx = async (
  params: DonationDashboardParam
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donations-trx', {
      params: params,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByProgram = async (
  programId: string,
  is_distributed: string
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donations-program', {
      params: {
        program_id: programId,
        is_distributed: is_distributed,
      },
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetDonationByPeriode = async (
  periode: string
): Promise<DonationByPeriodeResultInterface> => {
  try {
    let response = await ApiProgram.get('/donations-by-periode', {
      params: {
        periode: periode,
      },
    })
    return response.data as DonationByPeriodeResultInterface
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as DonationByPeriodeResultInterface
  }
}

export const GetDonationTransactionByPeriode = async (
  periode: string
): Promise<DonationByPeriodeResultInterface> => {
  try {
    let response = await ApiProgram.get('/donations-transaction-by-periode', {
      params: {
        periode: periode,
      },
    })
    return response.data as DonationByPeriodeResultInterface
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as DonationByPeriodeResultInterface
  }
}

export const GetDonationChannelPeriode = async (
  periode: string
): Promise<DonationChannelInterface> => {
  try {
    let response = await ApiProgram.get('/donations-by-channel', {
      params: {
        periode: periode,
      },
    })
    return response.data as DonationChannelInterface
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as DonationChannelInterface
  }
}

export const GetTotalDonationByKorca = async (
  params: TotalDonationKorcaParam
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-korca', {params: params})
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByKorcaInDirectionPage = async (
  id: string,
  params: TotalDonationKorcaParam
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-korca/' + id, {params: params})
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const TotalDonationV2 = async (
  params: GetAllDonationParam
): Promise<TotalDonationInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-v2', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationInterface = a.response?.data
    return response
  }
}

export const GetPlatformFee = async (): Promise<DonationPlatformFee> => {
  try {
    let response = await ApiProgram.get('get-platform-fee')
    return response.data as DonationPlatformFee
  } catch (e) {
    let a = e as AxiosError
    let response: DonationPlatformFee = a.response?.data
    return response
  }
}
