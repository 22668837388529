import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import FlowBuilderPage from '../modules/apps/crm/flow-builder/flow-builder-page'
import DashboardWrapper from '../modules/apps/dashboard/screens/dashboard.screen'
import DashboardAdminBranchScreen from '../modules/apps/dashboard/screens/dashboard-admin-branch.screen'
import DashboardAdminPusatScreen from '../modules/apps/dashboard/screens/dashboard-admin-pusat.screen'
import DashboardAdminSubBranchScreen from '../modules/apps/dashboard/screens/dashboard-admin-subbranch.screen'
import DashboardMitraLembagaScreen from '../modules/apps/dashboard/screens/dashboard-mitra-lembaga.screen'
import DashboardTaqurScreen from '../modules/apps/program/taqur/student-management/components/dashboard-taqur.component'
import MasterCsrScreen from '../modules/apps/csr/screens/master-csr.screen'
import CsrLeadScreen from '../modules/apps/csr/screens/csr-lead.screen'
import CsrTestimonialScreen from '../modules/apps/csr/screens/csr-testimonial.screen'
import CsrPartnerScreen from '../modules/apps/csr/screens/csr-partner.screen'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/crm/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/auth/user-management/UsersPage'))
  const PropertiesPage = lazy(
    () => import('../modules/apps/crm/properties-management/PropertiesPage')
  )
  const CustomerPage = lazy(() => import('../modules/apps/crm/customer-management/customer-page'))
  const ChannelPage = lazy(() => import('../modules/apps/crm/channel-management/channel-page'))
  const WhatsappChannelPage = lazy(
    () => import('../modules/apps/crm/whatsapp-channel-management/whatsapp-channel-page')
  )
  const CurrencyPage = lazy(
    () => import('../modules/apps/crm/currency-management/screens/currency-page.screen')
  )
  const DealPage = lazy(
    () => import('../modules/apps/crm/deal-management/screens/deal-page.screen')
  )
  const ProspectChannel = lazy(
    () => import('../modules/apps/crm/prospect-channel-management/screens/prospect-channel.screen')
  )
  const Task = lazy(() => import('../modules/apps/crm/task-management/screens/task.screen'))
  const ProgramCategory = lazy(
    () => import('../modules/apps/program/program-category/screens/program-category.screen')
  )
  const ContactGroup = lazy(
    () => import('../modules/apps/crm/contact-group/screens/contact-group.screen')
  )
  const Campaign = lazy(() => import('../modules/apps/crm/campaign/screens/campaign.screen'))
  const ProgramPage = lazy(() => import('../modules/apps/program/program/screens/program.screen'))
  const ProgramMitraPage = lazy(
    () => import('../modules/apps/program/program-mitra/screens/program-mitra.screen')
  )
  const NewsPage = lazy(() => import('../modules/apps/program/news/screens/news.screen'))
  const DonationTargetPage = lazy(
    () => import('../modules/apps/program/donation-target/screens/donation-target.screen')
  )

  const DistributionPage = lazy(
    () =>
      import(
        '../modules/apps/distribution/distribution-management/screens/distribution-alocation.screen'
      )
  )
  const PaymentPage = lazy(() => import('../modules/apps/program/payment/screens/payment.screen'))
  const DonationPage = lazy(
    () => import('../modules/apps/program/donation/screens/donation.screen')
  )
  const DonationManagementPage = lazy(
    () => import('../modules/apps/program/donation-management/screens/donation-management.screen')
  )
  const EReportCategoryPage = lazy(
    () => import('../modules/apps/crm/report-category/screens/report-category.screen')
  )
  const EReportPage = lazy(() => import('../modules/apps/crm/report/screens/report.screen'))
  const BannerPage = lazy(() => import('../modules/apps/program/banner/screens/banner.screen'))
  const DonationAffiliateDashboardPage = lazy(
    () => import('../modules/apps/program/program-affiliate/screens/program-affiliate.screen')
  )
  const CompanyPage = lazy(() => import('../modules/company/screens/company.screen'))
  const RolePage = lazy(() => import('../modules/apps/auth/role-management/screens/role.screen'))
  const DonationTypePage = lazy(
    () => import('../modules/apps/program/donation-type/screens/donation-type.screen')
  )
  const CompaniesPage = lazy(
    () => import('../modules/apps/auth/companies-management/screens/companies.screen')
  )
  const LevelAffiliator = lazy(
    () => import('../modules/apps/program/level-affiliator/screens/level-affiliator.screen')
  )
  const MessageTemplatePage = lazy(
    () => import('../modules/apps/crm/message-template-management/screens/message-template.screen')
  )
  const GroupManagementPage = lazy(
    () => import('../modules/apps/crm/group-management/screens/group.screen')
  )

  const ServiceUnitManagement = lazy(
    () => import('../modules/apps/crm/sevice-unit-management/screens/service-unit.screen')
  )

  const AffiliatorPage = lazy(
    () => import('../modules/apps/auth/affiliator-management/screens/affiliator.screen')
  )

  const VolunteersBulkPage = lazy(
    () => import('../modules/apps/auth/affiliasi-management/screens/volunteers-bulk-screen')
  )

  const BranchPage = lazy(
    () => import('../modules/apps/auth/branch-management/screens/branch-managements.screen')
  )

  const KorcaPage = lazy(
    () => import('../modules/apps/crm/korca-management/screens/korca-management.screen')
  )

  const GroupMember = lazy(
    () => import('../modules/apps/crm/group-management/screens/member.screen')
  )

  const KorcaMember = lazy(
    () => import('../modules/apps/crm/korca-management/screens/korca-member.screen')
  )

  const DirectionScreen = lazy(
    () => import('../modules/apps/program/program-affiliate/screens/direction.screen')
  )

  const PlatformDonationSettingPage = lazy(
    () =>
      import(
        '../modules/apps/auth/platform-donation-setting-management/screens/platform-donation-setting.screen'
      )
  )

  const EmailTemplatePage = lazy(
    () => import('../modules/apps/crm/email-templates/screens/email-template.screen')
  )

  const ProgramEventBannerPage = lazy(
    () => import('../modules/apps/program/program-event-banner/screens/program-event-banner.screen')
  )

  const DonaturPage = lazy(() => import('../modules/apps/crm/donatur-management/donatur-page'))

  const CommissionPage = lazy(
    () => import('../modules/apps/auth/commission-management/screens/commission.screen')
  )

  const AffiliatorPartner = lazy(
    () => import('../modules/apps/auth/affiliator-management/screens/affilator-partner.screen')
  )

  const PartnerPage = lazy(
    () => import('../modules/apps/program/partner-management/screens/partner.screen')
  )

  const StudentManagement = lazy(
    () =>
      import('../modules/apps/program/taqur/student-management/screens/student-management.screen')
  )

  const PropertiesTaqurManagementPage = lazy(
    () =>
      import(
        '../modules/apps/program/taqur/properties-management/screens/properties-taqur-management.screen'
      )
  )

  const ProgramTaqurPage = lazy(
    () => import('../modules/apps/program/taqur/program-management/screens/program-taqur.screen')
  )

  const FAQPage = lazy(() => import('../modules/apps/auth/faq-management/screens/faq.screen'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/omni-management/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/flow-builder-management/*'
          element={
            <SuspensedView>
              <FlowBuilderPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/customer-management/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/crm/properties-management/*'
          element={
            <SuspensedView>
              <PropertiesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/channel-management/*'
          element={
            <SuspensedView>
              <ChannelPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/crm/currency-management/*'
          element={
            <SuspensedView>
              <CurrencyPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/prospect-management/*'
          element={
            <SuspensedView>
              <DealPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/crm/prospect-channel-management/*'
          element={
            <SuspensedView>
              <ProspectChannel />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/task-management/*'
          element={
            <SuspensedView>
              <Task />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/wa-channel-management/*'
          element={
            <SuspensedView>
              <WhatsappChannelPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/contact-group-management/*'
          element={
            <SuspensedView>
              <ContactGroup />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/broadcast-management/*'
          element={
            <SuspensedView>
              <Campaign />
            </SuspensedView>
          }
        />
        <Route
          path='master/program/program-category-management/*'
          element={
            <SuspensedView>
              <ProgramCategory />
            </SuspensedView>
          }
        />
        <Route
          path='master/program/donation-type-management/*'
          element={
            <SuspensedView>
              <DonationTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/program-management/*'
          element={
            <SuspensedView>
              <ProgramPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/distribution/distribution-management/*'
          element={
            <SuspensedView>
              <DistributionPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/donatur-management/*'
          element={
            <SuspensedView>
              <DonaturPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/program-mitra/*'
          element={
            <SuspensedView>
              <ProgramMitraPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/news-management/*'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/program/donation-target-management/*'
          element={
            <SuspensedView>
              <DonationTargetPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/program/payment-management/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/donation/donation-management/*'
          element={
            <SuspensedView>
              <DonationPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/donation-management/*'
          element={
            <SuspensedView>
              <DonationManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/crm/report-category-management/*'
          element={
            <SuspensedView>
              <EReportCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/crm/message-template-management/*'
          element={
            <SuspensedView>
              <MessageTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/crm/report-management/*'
          element={
            <SuspensedView>
              <EReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/banner-management/*'
          element={
            <SuspensedView>
              <BannerPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/program-event-banner-management/*'
          element={
            <SuspensedView>
              <ProgramEventBannerPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program-affiliate/*'
          element={
            <SuspensedView>
              <DonationAffiliateDashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/admin-pusat-dashboard/*'
          element={
            <SuspensedView>
              <DashboardAdminPusatScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/admin-branch-dashboard/*'
          element={
            <SuspensedView>
              <DashboardAdminBranchScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/admin-subbranch-dashboard/*'
          element={
            <SuspensedView>
              <DashboardAdminSubBranchScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/mitra-lembaga-dashboard/*'
          element={
            <SuspensedView>
              <DashboardMitraLembagaScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/admin-taqur/*'
          element={
            <SuspensedView>
              <DashboardTaqurScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/company-management/*'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/companies-management/*'
          element={
            <SuspensedView>
              <CompaniesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/level-affiliator-management/*'
          element={
            <SuspensedView>
              <LevelAffiliator />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/commission-management/*'
          element={
            <SuspensedView>
              <CommissionPage />
            </SuspensedView>
          }
        />

        <Route
          path='/apps/group-management/*'
          element={
            <SuspensedView>
              <GroupManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/service-unit-management/*'
          element={
            <SuspensedView>
              <ServiceUnitManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/affiliator-management/*'
          element={
            <SuspensedView>
              <AffiliatorPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/affiliator-partner-management/*'
          element={
            <SuspensedView>
              <AffiliatorPartner />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/affiliator-bulk-management/*'
          element={
            <SuspensedView>
              <VolunteersBulkPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/branch-management/*'
          element={
            <SuspensedView>
              <BranchPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/program/program-affiliate/*'
          element={
            <SuspensedView>
              <DonationAffiliateDashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/program/partner-management/*'
          element={
            <SuspensedView>
              <PartnerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/korca-management/*'
          element={
            <SuspensedView>
              <KorcaPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/member-management/*'
          element={
            <SuspensedView>
              <GroupMember />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/korca-member-management/*'
          element={
            <SuspensedView>
              <KorcaMember />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/platform-donation-settings-management/*'
          element={
            <SuspensedView>
              <PlatformDonationSettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/direction-screen/*'
          element={
            <SuspensedView>
              <DirectionScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/master/crm/email-template-management/*'
          element={
            <SuspensedView>
              <EmailTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/program/taqur/student-management/*'
          element={
            <SuspensedView>
              <StudentManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/taqur/properties-management/*'
          element={
            <SuspensedView>
              <PropertiesTaqurManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/program/taqur/program-management/*'
          element={
            <SuspensedView>
              <ProgramTaqurPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/faq-management/*'
          element={
            <SuspensedView>
              <FAQPage />
            </SuspensedView>
          }
        />

        {/* CSR Management */}
        <Route
          path='/apps/csr-management/master-csr'
          element={
            <SuspensedView>
              <MasterCsrScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/csr-management/csr-lead'
          element={
            <SuspensedView>
              <CsrLeadScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/csr-management/csr-testimonial'
          element={
            <SuspensedView>
              <CsrTestimonialScreen />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/csr-management/csr-partner'
          element={
            <SuspensedView>
              <CsrPartnerScreen />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
