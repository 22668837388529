import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { useAuth } from "../../../auth"
import { authPostgrest } from "../../../../api/postgrest";
import { useProvinceStore } from "../../auth/provinces-management/states/province.state";
import { InputDatePicker } from "../../../forms/components/input.component";
import DashboardCardAffiliatorComponent from "../../auth/affiliator-management/components/dashboard-card-affiliator.component";
import { generateCurrencyFormat } from "../../../../helpers/generate-currency-format";
import { KTCard } from "../../../../../_metronic/helpers";
import { LoadingComponent } from "../../crm/properties-management/components/loading.component";
import { ProvinceData } from "../../auth/provinces-management/interfaces/province-result.interface";
import { useCityStore } from "../../auth/cities-management/states/city.state";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const dashboardAdminBranchBreadcrumbs: Array<PageLink> = [
  {
    title: 'Admin Pusat',
    path: '/apps/admin-pusat-dashboard/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardAdminPusatNewComponent = () => {
  const { currentUser } = useAuth()
  const { getProvincesRest, provinceItems, provinceLoading, totalDonates, totalDonors } = useProvinceStore();
  // const { startDate, endDate, setStartDate, setEndDate } = useDashboardStore()
  const { getCitiesRest, cityItems, cityLoading, totalCityDonates, totalCityDonors } = useCityStore();
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [totalVolunteers, setTotalVolunteers] = useState<number>(0)
  const [totalCityVolunteers, setTotalCityVolunteers] = useState<number>(0)
  const [selectedProvince, setSelectedProvince] = useState<ProvinceData>()
  const [activeMenu, setActiveMenu] = useState<string>('province')
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  useEffect(() => {
    getProvincesRest(currentUser?.data?.company?.id, startDate, endDate);
  }, [currentUser, startDate, endDate])

  // useEffect(() => {
  //   const getTotalDonations = async () => {
  //     const responses = await postgrest
  //       .from('donations')
  //       .select(`
  //         amount.sum(), amount.count()
  //       `)
  //       .eq('company_id', currentUser?.data?.company?.id)
  //       .eq('status', 'settlement')
  //       .is('deleted_at', 'NULL')
  //       .neq('affiliate_code', '')
  //       .lte('transfer_date', dateTimeISOFormatterEndDate(endDate))
  //       .gte('transfer_date', dateTimeISOFormatterStartDate(startDate))
  //       .single()
  //     setTotalDonations(responses?.data?.sum)
  //     setTotalDonors(responses?.data?.count)
  //   }
  //   getTotalDonations()
  // }, [currentUser, startDate, setStartDate])

  useEffect(() => {
    const getTotalVolunteers = async () => {
      const responses = await authPostgrest
        .from('users_with_relations')
        .select(`
          id.count()
        `)
        .is('deleted_at', 'NULL')
        .eq('company_id', currentUser?.data?.company?.id)
        .eq('role_name', 'Relawan')
        .single()
      setTotalVolunteers(responses.data.count)
    }
    getTotalVolunteers()
  }, [currentUser])

  useEffect(() => {
    if (selectedProvince) {
      getCitiesRest(currentUser?.data?.company?.id, selectedProvince, startDate, endDate)

      const getTotalVolunteers = async () => {
        const responses = await authPostgrest
          .from('users_with_relations')
          .select(`
            id.count()
          `)
          .is('deleted_at', 'NULL')
          .eq('company_id', currentUser?.data?.company?.id)
          .like('affiliate_code', `${selectedProvince?.acronym}%`)
          .eq('role_name', 'Relawan')
          .single()
        setTotalCityVolunteers(responses.data.count)
      }
      getTotalVolunteers()
    }
  }, [selectedProvince, currentUser, startDate, endDate])

  return (
    <>
      <PageTitle breadcrumbs={dashboardAdminBranchBreadcrumbs}>
        {`DASHBOARD - ADMIN PUSAT - ${currentUser?.data?.email}`}
      </PageTitle>
      <div className="col-12" style={{ marginBottom: -20 }}>
        <div className='col-12' style={{ marginBottom: -20 }}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>

        {
          activeMenu === 'province'
            ?
            <>
              <div className='row gy-5 g-xl-8 mb-5'>
                <DashboardCardAffiliatorComponent
                  title='Jumlah Donasi'
                  color='success'
                  value={totalDonors}
                  subtitle='Jumlah Donasi'
                  loading={provinceLoading}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('donation')
                  }}
                />
                <DashboardCardAffiliatorComponent
                  title='Donasi Terkumpul'
                  color='success'
                  value={generateCurrencyFormat(totalDonates)}
                  subtitle='Donasi Terkumpul'
                  loading={provinceLoading}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('donation')
                  }}
                />
                <DashboardCardAffiliatorComponent
                  title='Jumlah Relawan'
                  color='success'
                  value={totalVolunteers}
                  subtitle='Jumlah Relawan'
                  loading={false}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('dashboard')
                  }}
                />
              </div>

              <button className="btn btn-primary mb-5" onClick={e => {
                if (!exportLoading) {
                  setExportLoading(true);
                  // 1. Create a new Excel workbook
                  const workbook = XLSX.utils.book_new();

                  interface exportData {
                    name?: string
                    total_donate_p?: number
                    total_donate_s?: number
                    total_donate_u?: number
                  }
                  const exportDatas: exportData[] = [];

                  for (const item of provinceItems) {
                    exportDatas.push({
                      name: item.name,
                      total_donate_p: item.total_donate_p,
                      total_donate_s: item.total_donate_s,
                      total_donate_u: item.total_donate_u,
                    })
                  }
                  // 2. Convert JSON data to worksheet
                  // XLSX.utils.json_to_sheet(data, opts);
                  const worksheet = XLSX.utils.json_to_sheet(exportDatas);

                  // 3. Add worksheet to the workbook
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // "Sheet1" is the worksheet name.  Change if needed.

                  // 4. Generate Excel file (XLSX format is generally preferred)
                  // XLSX.write(workbook, opts);
                  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                  // 5. Save the file using FileSaver.js
                  // fileSaver.saveAs(blob, filename, dont_auto_bom);
                  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                  setExportLoading(false);
                  saveAs(data, 'data.xlsx');
                }
              }}>
                {exportLoading ? 'Generating Export...' : 'Export to Excel'}
              </button>

              <KTCard>
                <div className='card-header border-0 pt-6'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Provinsi</span>
                  </h3>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    {provinceLoading ? <LoadingComponent /> : <></>}
                    <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                      <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <tr>
                          <th>Nama</th>
                          <th>Total Donasi Komunitas P</th>
                          <th>Total Donasi Komunitas S</th>
                          <th>Total Donasi Komunitas U</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {provinceItems?.length === 0 ? (
                          <tr>
                            <td colSpan={10}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No Properties Found
                              </div>
                            </td>
                          </tr>
                        ) : (
                          provinceItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <a
                                    href='#'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setActiveMenu('city')
                                      setSelectedProvince(item)
                                      // navigate(`/apps/admin-branch-dashboard/dashboard/${item.acronym}`, {
                                      //   state: {
                                      //     province_code: item.acronym,
                                      //     city_code: item.acronym,
                                      //     community_code: 1,
                                      //   },
                                      // })
                                    }}
                                  >
                                    {item?.name.toUpperCase()}
                                  </a>
                                </td>
                                <td>{generateCurrencyFormat(item?.total_donate_p ?? 0)}</td>
                                <td>{generateCurrencyFormat(item?.total_donate_s ?? 0)}</td>
                                <td>{generateCurrencyFormat(item?.total_donate_u ?? 0)}</td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </KTCard>
            </>
            : null
        }

        {
          activeMenu === 'city' && selectedProvince
            ?
            <>
              <div className='row gy-5 g-xl-8 mb-5'>
                <DashboardCardAffiliatorComponent
                  title='Jumlah Donasi'
                  color='success'
                  value={totalCityDonors}
                  subtitle='Jumlah Donasi'
                  loading={cityLoading}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('donation')
                  }}
                />
                <DashboardCardAffiliatorComponent
                  title='Donasi Terkumpul'
                  color='success'
                  value={generateCurrencyFormat(totalCityDonates)}
                  subtitle='Donasi Terkumpul'
                  loading={cityLoading}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('donation')
                  }}
                />
                <DashboardCardAffiliatorComponent
                  title='Jumlah Relawan'
                  color='success'
                  value={totalCityVolunteers}
                  subtitle='Jumlah Relawan'
                  loading={false}
                  className='col-sm-4 col-xl-4'
                  onClick={(e) => {
                    e.preventDefault()
                    // setActiveMenu('dashboard')
                  }}
                />
              </div>

              <div className="d-flex flex-row">
                <button className="btn btn-primary mb-5 mr-1" onClick={e => {
                  setSelectedProvince(null)
                  setActiveMenu('province')
                }}>
                  Kembali ke pilihan Provinsi
                </button>

                <button className="btn btn-primary mb-5" onClick={e => {
                  if (!exportLoading) {
                    setExportLoading(true);
                    // 1. Create a new Excel workbook
                    const workbook = XLSX.utils.book_new();

                    interface exportData {
                      name?: string
                      total_donate_p?: number
                      total_donate_s?: number
                      total_donate_u?: number
                    }
                    const exportDatas: exportData[] = [];

                    for (const item of cityItems) {
                      exportDatas.push({
                        name: item.name,
                        total_donate_p: item.total_donate_p,
                        total_donate_s: item.total_donate_s,
                        total_donate_u: item.total_donate_u,
                      })
                    }
                    // 2. Convert JSON data to worksheet
                    // XLSX.utils.json_to_sheet(data, opts);
                    const worksheet = XLSX.utils.json_to_sheet(exportDatas);

                    // 3. Add worksheet to the workbook
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // "Sheet1" is the worksheet name.  Change if needed.

                    // 4. Generate Excel file (XLSX format is generally preferred)
                    // XLSX.write(workbook, opts);
                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                    // 5. Save the file using FileSaver.js
                    // fileSaver.saveAs(blob, filename, dont_auto_bom);
                    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    setExportLoading(false);
                    saveAs(data, 'data.xlsx');
                  }
                }}>
                  {exportLoading ? 'Generating Export...' : 'Export to Excel'}
                </button>
              </div>

              <KTCard>
                <div className='card-header border-0 pt-6'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>
                      {`Provinsi ${selectedProvince?.name}`}
                    </span>
                  </h3>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    {cityLoading ? <LoadingComponent /> : <></>}
                    <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                      <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <tr>
                          <th>Nama</th>
                          <th>Total Donasi Komunitas P</th>
                          <th>Total Donasi Komunitas S</th>
                          <th>Total Donasi Komunitas U</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {cityItems?.length === 0 ? (
                          <tr>
                            <td colSpan={10}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No Properties Found
                              </div>
                            </td>
                          </tr>
                        ) : (
                          cityItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {item?.name.toUpperCase()}
                                  {/* <a
                                    href='#'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      // setSelectedProvince(item)
                                      // navigate(`/apps/admin-branch-dashboard/dashboard/${item.acronym}`, {
                                      //   state: {
                                      //     province_code: item.acronym,
                                      //     city_code: item.acronym,
                                      //     community_code: 1,
                                      //   },
                                      // })
                                    }}
                                  >
                                    {item?.name.toUpperCase()}
                                  </a> */}
                                </td>
                                <td>{generateCurrencyFormat(item?.total_donate_p ?? 0)}</td>
                                <td>{generateCurrencyFormat(item?.total_donate_s ?? 0)}</td>
                                <td>{generateCurrencyFormat(item?.total_donate_u ?? 0)}</td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </KTCard>
            </>
            : null
        }
      </div>
    </>
  );
}

export default DashboardAdminPusatNewComponent;