import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { useAuth } from '../../../auth'
import { InputDatePicker } from '../../../forms/components/input.component'
import MemberScreen from '../../crm/group-management/screens/member.screen'
import DirectionScreen from '../../program/program-affiliate/screens/direction.screen'
import DonationAffiliateDashboardPage from '../../program/program-affiliate/screens/program-affiliate.screen'
import AdminKpiComponent from '../components/admin-kpi.component'
import CustomerProfileChart from '../components/customer-profile-chart.component'
import CustomerSegmentChart from '../components/customer-segment-chart.component'
import DonationByPeriodeChartComponent from '../components/donation-by-periode-chart.component'
import DonationPerformanceChannelChartComponent from '../components/donation-performance-channel-chart.component'
import DonationTrxByPeriodeChartComponent from '../components/donation-trx-by-periode-chart.component'
import TopProgramCardComponent from '../components/top-program-card.component'
import TotalActiveProgramCardComponent from '../components/total-active-program-card.component'
import TotalCustomerCardComponent from '../components/total-customer-card.component'
import TotalDonationCardComponent from '../components/total-donation.card.component'
import TotalRoomCardComponent from '../components/total-new-case-card.component'
import { useDashboardStore } from '../states/dashboard.state'
import DashboardAdminBranchScreen from './dashboard-admin-branch.screen'
import DashboardAdminSubBranchScreen from './dashboard-admin-subbranch.screen'
import DashboardMitraLembagaScreen from './dashboard-mitra-lembaga.screen'
import DashboardAdminPusatScreen from './dashboard-admin-pusat.screen'
import DashboardTaqurScreen from '../../program/taqur/student-management/components/dashboard-taqur.component'
import ClientListComponent from '../../program/clients/components/client-list.component'
import DashboardCardComponent from '../components/dashboard-card.component'
import { generateCurrencyFormat } from '../../../../helpers/generate-currency-format'
import { useEffect, useState } from 'react'
import { useGenAiStore } from '../../program/program/states/genai.state'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { dateTimeISOFormatterEndDate, dateTimeISOFormatterStartDate } from '../../../../../_metronic/helpers/datetime-formatter'
import { programChartColumns, ProgramChartDataTable } from '../components/program-chart-data-table.component'
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DashboardScreen = () => {
  const { startDate, endDate, setStartDate, setEndDate, platformFee, platformFeeLoading, getPlatformFee, getProgramCharts, loadingProgramCharts, programCharts, programChartStartDate, programChartEndDate, setProgramChartStartDate, setProgramChartENdDate } = useDashboardStore()
  const { currentUser } = useAuth()
  const { genAiLoading, genAiResponse: genAiResp, getGenAi, resetGenAi } = useGenAiStore()
  const [prompt, setPrompt] = useState<string>('')
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  useEffect(() => {
    getPlatformFee()
    console.log("zxczxc", programChartStartDate, programChartEndDate, new Date())
  }, [])

  useEffect(() => {
    getProgramCharts(currentUser?.data?.company?.id, dateTimeISOFormatterStartDate(programChartStartDate), dateTimeISOFormatterEndDate(programChartEndDate))
  }, [currentUser, programChartStartDate, programChartEndDate])

  if (currentUser?.data?.company?.id === '21db0100-5e59-4a79-90b2-eeb6a7a7097e') {
    return (
      <>
        <iframe
          src={currentUser?.data?.iframe_url}
          frameBorder={0}
          width={'100%'}
          height={1000}
          allowTransparency
          title='Dashboard Universa'
        />
      </>
    )
  }

  if (currentUser?.data?.company?.id === '116702c0-7602-460f-a082-110cdb24021d') {
    return (
      <>
        <iframe
          src={currentUser?.data?.iframe_url}
          frameBorder={0}
          width={'100%'}
          height={1000}
          allowTransparency
          title='Dashboard Universa'
        />
      </>
    )
  }

  return (
    <>
      {
        currentUser?.data?.company?.id !== 'ab7ae967-ad36-4874-97ab-365fff019bb5'
          ?
          <>
            <div className='alert alert-primary d-flex align-items-center p-5'>
              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-primary'>Coba Gen AI Kami 😄</h4>
                <span>Silahkan isi dengan prompt seperti anda bermain ChatGPT 🔥</span>
              </div>
            </div>
            <KTCard>
              <KTCardBody>
                <input
                  onChange={(e) => {
                    setPrompt(e.target?.value)
                  }}
                  placeholder={'Isi dengan kalimat yang ajaib'}
                  value={prompt}
                  type={'text'}
                  name={'gen_ai_prompt'}
                  // disabled={disabled}
                  // hidden={hidden}
                  className={'form-control mb-3 mb-lg-0'}
                // required={required}
                />
                <button
                  className='btn btn-primary mt-2'
                  onClick={(e) => {
                    e.preventDefault()
                    if (!genAiLoading) {
                      getGenAi(prompt)
                    }
                  }}
                >
                  {genAiLoading ? 'Mohon tunggu, sedang mengenerate kata kata ajaib...' : 'Generate'}
                </button>

                <textarea
                  placeholder={"Hasil dari response gen ai disini ya 😄..."}
                  className={'form-control mb-3 mb-lg-0'}
                  style={{
                    marginTop: 10,
                  }}
                  value={genAiResp?.data?.response}
                >
                  {genAiResp?.data?.response}
                </textarea>
              </KTCardBody>
            </KTCard>
          </>
          : null
      }

      <KTCard className='my-5'>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Realtime Program Update 🔥</span>
          </h3>

          <div className='card-toolbar' onClick={e => {
            e.preventDefault();
            if (!exportLoading) {
              setExportLoading(true);

              // 1. Create a new Excel workbook
              const workbook = XLSX.utils.book_new();

              interface exportData {
                title?: string;
                slug?: string;
                total_donations?: number;
                total_donors?: number;
              }
              const exportDatas: exportData[] = [];
              for (const item of programCharts.data) {
                exportDatas.push({
                  title: item.title,
                  slug: item.slug,
                  total_donations: item.donations?.at(0)?.total_donates ?? 0,
                  total_donors: item.donations?.at(0)?.total_trx ?? 0,
                })
              }
              // 2. Convert JSON data to worksheet
              // XLSX.utils.json_to_sheet(data, opts);
              const worksheet = XLSX.utils.json_to_sheet(exportDatas);

              // 3. Add worksheet to the workbook
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // "Sheet1" is the worksheet name.  Change if needed.

              // 4. Generate Excel file (XLSX format is generally preferred)
              // XLSX.write(workbook, opts);
              const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

              // 5. Save the file using FileSaver.js
              // fileSaver.saveAs(blob, filename, dont_auto_bom);
              const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
              setExportLoading(false);
              saveAs(data, 'data.xlsx');
            }
          }}>
            <button className='btn btn-primary'>
              Export to Excel
            </button>
          </div>
        </div>
        <KTCardBody>
          <div className='col-12' style={{ marginLeft: -15, marginTop: -20 }}>
            <div className='row'>
              <div className='col-2'>
                <DatePicker
                  selected={programChartStartDate}
                  onChange={(e) => setProgramChartStartDate(e)}
                  className={'form-control'}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  selected={programChartEndDate}
                  onChange={(e) => setProgramChartENdDate(e)}
                  className={'form-control'}
                />
              </div>
            </div>
          </div>
          <ProgramChartDataTable
            columns={programChartColumns}
            data={programCharts?.data || []}
          />
        </KTCardBody>
      </KTCard>

      {
        currentUser?.data?.company?.id !== 'ab7ae967-ad36-4874-97ab-365fff019bb5' &&
          currentUser?.data?.company?.id !== 'c719ac4a-dedb-4102-8be0-f78898f7cd35'
          ?
          <div className='alert alert-primary d-flex align-items-center p-5 mt-5'>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-primary'>Perhatian</h4>
              <span>Anda memiliki outstanding Platform Fee</span>
            </div>
          </div>
          : null
      }

      {
        currentUser?.data?.company?.id !== 'ab7ae967-ad36-4874-97ab-365fff019bb5' &&
          currentUser?.data?.company?.id !== 'c719ac4a-dedb-4102-8be0-f78898f7cd35'
          ?
          <DashboardCardComponent
            title='Platform Fee'
            color='danger'
            value={generateCurrencyFormat(platformFee)}
            subtitle='Outstanding Platform Fee'
            loading={platformFeeLoading}
            className='mb-5'
          />
          : null
      }

      <div className='alert alert-primary d-flex align-items-center p-5'>
        <div className='d-flex flex-column'>
          <h4 className='mb-1 text-primary'>Perhatian</h4>
          <span>Data yang ditampilkan pada saat awal adalah data 30 hari kebelakang</span>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        <div className='col-12' style={{ marginBottom: -20 }}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>

        <TotalDonationCardComponent />
        <TotalCustomerCardComponent />
        <TotalActiveProgramCardComponent />

        {currentUser?.data?.role?.slug === 'admin-platform' ? (
          <></>
        ) : (
          <>
            <TotalRoomCardComponent />
            {/* Admin KPI */}
            <AdminKpiComponent />
            {/* Top 10 Programs */}
            <TopProgramCardComponent />
          </>
        )}
        {currentUser?.data?.company?.id === '21db0100-5e59-4a79-90b2-eeb6a7a7097e' ? (
          <ClientListComponent />
        ) : null}

        {/* Pencapaian Donasi */}
        <DonationByPeriodeChartComponent />

        {/* Pencapaian Transaksi */}
        <DonationTrxByPeriodeChartComponent />
        {/* <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget2 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget3 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget4 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget6 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget7 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        {/* <ChartsWidget8 className='card-xxl-stretch mb-5 mb-xl-10' /> */}

        {/* Donatur Segmen */}
        <CustomerSegmentChart />

        {/* Donatur Profile */}
        <CustomerProfileChart />

        {/* Donation Performance By Channel */}
        <DonationPerformanceChannelChartComponent />
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      {currentUser?.data?.role?.slug ? (
        <>
          {currentUser?.data?.role?.slug === 'admin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'superadmin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'lead-branch' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-branch' &&
            currentUser?.data?.company?.id === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ? (
            <DashboardScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-branch' &&
            currentUser?.data?.company?.id === 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
            <DashboardScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-platform' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'user' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'relawan' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'bendahara' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'korca' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'direksi' ? <DirectionScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-provinsi' ? (
            <DonationAffiliateDashboardPage />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-kota' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-pusat' ? <DashboardAdminPusatScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-branch' &&
            currentUser?.data?.company?.id === 'ab7ae967-ad36-4874-97ab-365fff019bb5' ? (
            <DashboardAdminBranchScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-subbranch' ? (
            <DashboardAdminSubBranchScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'mitra-lembaga' ? (
            <DashboardMitraLembagaScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-taqur' ? <DashboardTaqurScreen /> : null}
        </>
      ) : null}
    </>
  )
}

export default DashboardWrapper
