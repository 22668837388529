import { useEffect, useState } from "react";
import { useAuth } from "../../../auth";
import { useDashboardAffiliateStore } from "../../program/program-affiliate/states/program-affiliate.state";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { dashboardAdminBranchBreadcrumbs } from "./dashboard-admin-branch.screen";
import { InputDatePicker } from "../../../forms/components/input.component";
import DashboardCardAffiliatorComponent from "../../auth/affiliator-management/components/dashboard-card-affiliator.component";
import { generateCurrencyFormat } from "../../../../helpers/generate-currency-format";
import { KTCard } from "../../../../../_metronic/helpers";
import { CityData } from "../../auth/cities-management/interfaces/city-result.interface";
import { useCityStore } from "../../auth/cities-management/states/city.state";
import { LoadingComponent } from "../../crm/properties-management/components/loading.component";
import { useDonationStore } from "../../program/donation/states/donation.state";
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom'
import useCopyToClipboard from "../../program/program-affiliate/components/copytToClipBoard"
import { toast } from "react-toastify";
import { timeDateFormatter } from "../../../../../_metronic/helpers/datetime-formatter";
import { generateStatusBadge } from "../../../../../_metronic/helpers/generate-status-badge";

const DashboardAdminSubbranchNewComponent = () => {
  const { currentUser } = useAuth();
  const { getCurrentCitiesRest, totalCityDonates, totalCityDonors, selectedCity, totalVolunteerCity, getVolunteerCitiesRest, selectedProvince, currentCommunity, cityLoading, affiliateItems } = useCityStore();
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [activeMenu, setActiveMenu] = useState<string>('volunteer')
  const { getRestDonationAff, loadingRestDonations, restDonations, restDonationModel, exportLoading } = useDonationStore();
  const navigate = useNavigate();
  const [value, copy] = useCopyToClipboard()

  useEffect(() => {
    if (currentUser) {
      getCurrentCitiesRest(currentUser.data?.affiliate_code, currentUser?.data?.company?.id, startDate, endDate)
    }
  }, [currentUser, startDate, endDate])

  useEffect(() => {
    if (selectedProvince && selectedCity) {
      getVolunteerCitiesRest(selectedProvince, selectedCity, currentCommunity, currentUser?.data?.company?.id, startDate, endDate)
      getRestDonationAff(
        1,
        [currentUser?.data?.company?.id],
        `${selectedProvince.acronym}${selectedCity.acronym}`,
        currentCommunity,
        startDate,
        endDate,
        false
      );
    }
  }, [selectedProvince, selectedCity, currentCommunity, currentUser, startDate, endDate])

  const handlePageClick = (e: any) => {
    const selectedPage = +e.selected + 1;
    getRestDonationAff(
      selectedPage,
      [currentUser?.data?.company?.id],
      `${selectedProvince.acronym}${selectedCity.acronym}`,
      currentCommunity,
      startDate,
      endDate,
      false
    );
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardAdminBranchBreadcrumbs}>
        {`DASHBOARD - ${currentUser?.data?.email}`}
      </PageTitle>

      <div className="col-12" style={{ marginBottom: -20 }}>
        <div className='row'>
          <div className='col-2'>
            <InputDatePicker
              id={'start_date'}
              title={'Start Date'}
              name={'start_date'}
              type={'date'}
              value={startDate}
              onChangeDate={(e) => {
                setStartDate(e)
              }}
            />
          </div>
          <div className='col-2'>
            <InputDatePicker
              id={'end_date'}
              title={'End Date'}
              name={'end_date'}
              type={'date'}
              value={endDate}
              onChangeDate={(e) => {
                setEndDate(e)
              }}
            />
          </div>
        </div>
      </div>

      {
        activeMenu === 'volunteer'
          ?
          <>
            <div className='row gy-5 g-xl-8 mb-5'>
              <DashboardCardAffiliatorComponent
                title='Jumlah Donasi'
                color='success'
                value={totalCityDonors}
                subtitle='Jumlah Donasi'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  // setActiveMenu('donation')
                }}
              />
              <DashboardCardAffiliatorComponent
                title='Donasi Terkumpul'
                color='success'
                value={generateCurrencyFormat(totalCityDonates)}
                subtitle='Donasi Terkumpul'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  setActiveMenu('donation')
                }}
              />
              <DashboardCardAffiliatorComponent
                title='Jumlah Relawan'
                color='success'
                value={totalVolunteerCity}
                subtitle='Jumlah Relawan'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  // setActiveMenu('dashboard')
                }}
              />
            </div>

            <KTCard>
              <div className='card-header border-0 pt-6'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>
                    {`${selectedCity?.name}`}
                  </span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  {cityLoading ? <LoadingComponent /> : <></>}
                  <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                    <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Total Donasi</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {affiliateItems?.length === 0 ? (
                        <tr>
                          <td colSpan={10}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              No Properties Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        affiliateItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item?.affiliate_code.toUpperCase()}
                              </td>
                              <td>{generateCurrencyFormat(item?.total_donations)}</td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCard>
          </>
          : null
      }

      {
        activeMenu === 'donation'
          ?
          <>
            <div className='row gy-5 g-xl-8 mb-5'>
              <DashboardCardAffiliatorComponent
                title='Jumlah Donasi'
                color='success'
                value={totalCityDonors}
                subtitle='Jumlah Donasi'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  // setActiveMenu('donation')
                }}
              />
              <DashboardCardAffiliatorComponent
                title='Donasi Terkumpul'
                color='success'
                value={generateCurrencyFormat(totalCityDonates)}
                subtitle='Donasi Terkumpul'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  setActiveMenu('donation')
                }}
              />
              <DashboardCardAffiliatorComponent
                title='Jumlah Relawan'
                color='success'
                value={totalVolunteerCity}
                subtitle='Jumlah Relawan'
                loading={false}
                className='col-sm-4 col-xl-4'
                onClick={(e) => {
                  e.preventDefault()
                  // setActiveMenu('dashboard')
                }}
              />
            </div>

            <div className="d-flex flex-row">
              <button className="btn btn-primary mb-5 mr-1" onClick={() => {
                setActiveMenu('volunteer')
              }}>
                Kembali ke sebelumnya
              </button>

              <button className="btn btn-primary mb-5" onClick={e => {
                if (!exportLoading) {
                  getRestDonationAff(
                    1,
                    [currentUser?.data?.company?.id],
                    `${selectedProvince.acronym}${selectedCity.acronym}`,
                    currentCommunity,
                    startDate,
                    endDate,
                    true
                  );
                }
              }}>
                {exportLoading ? 'Generating Export...' : 'Export to Excel'}
              </button>
            </div>

            <KTCard>
              <div className='card-header border-0 pt-6'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>
                    {`Provinsi ${selectedProvince?.name}`}
                  </span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  {loadingRestDonations ? <LoadingComponent /> : <></>}
                  <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                    <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <tr>
                        <th>Donatur</th>
                        <th>Program</th>
                        <th>Payment</th>
                        <th>Amount</th>
                        <th>Kode Unik</th>
                        <th>Referal Code</th>
                        <th>Province</th>
                        <th>City</th>
                        <th>Transfer Date</th>
                        <th>Created Date</th>
                        <th>Status</th>
                        <th>E-Kwitansi</th>
                        <th>BSZ</th>
                        <th>Bukti Pembayaran</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {restDonations?.length === 0 ? (
                        <tr>
                          <td colSpan={10}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              No Properties Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        restDonations.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex flex-column">
                                  <span
                                    style={{
                                      marginBottom: 2,
                                      cursor: 'copy',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      copy(item.invoice_code)
                                      toast.success(`Berhasil menyalin kode invoice`)
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: 'copy',
                                      }}
                                      className='badge badge-secondary'
                                    >
                                      {item.invoice_code}
                                    </span>
                                  </span>
                                  <span>{item.donor_name}</span>
                                  <span>{item.donor_email}</span>
                                  <span>{item.donor_phone}</span>
                                </div>

                              </td>
                              <td>{item.programs?.title}</td>
                              <td>{item?.payments?.name}</td>
                              <td>{generateCurrencyFormat(item?.amount)}</td>
                              <td>{generateCurrencyFormat(item?.unique_code)}</td>
                              <td>{item.affiliate_code}</td>
                              <td>{item.province_name}</td>
                              <td>{item.city_name}</td>
                              <td>
                                {
                                  item.status === 'settlement'
                                    ? timeDateFormatter(Date.parse(item?.updated_at?.toString()))
                                    : '-'
                                }
                              </td>
                              <td>{timeDateFormatter(Date.parse(item?.created_at?.toString()))}</td>
                              <td>{generateStatusBadge(item.status)}</td>
                              <td>
                                <span
                                  className='badge badge-success'
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (
                                      item.status === 'settlement' ||
                                      item.status === 'capture'
                                    ) {
                                      navigate(
                                        `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                                      )
                                    } else {
                                      toast.error('Tidak dapat membuka ekwitansi, transaksi belum settle')
                                    }
                                  }}
                                >
                                  E-Kwitansi
                                </span>
                              </td>
                              {currentUser?.data?.company?.company_type !== 'umum' ? (
                                <td>
                                  <span
                                    className='badge badge-success'
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      if (
                                        item.status === 'settlement' ||
                                        item.status === 'capture'
                                      ) {
                                        navigate(
                                          `/apps/donation/donation-management/bukti-setor-zakat?donation_id=${item.id}`
                                        )
                                      } else {
                                        toast.error(
                                          'Tidak dapat membuka ekwitansi, transaksi belum settle'
                                        )
                                      }
                                    }}
                                  >
                                    Bukti Setor Zakat
                                  </span>
                                </td>
                              ) : null}
                              <td>
                                <span
                                  className='badge badge-success'
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  {item?.proof_of_payment ? 'Ada' : 'Tidak Ada'}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                </div>

                <div className='card-footer'>
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'...'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    pageCount={restDonationModel?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination pagination-sm m-0 float-right'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </KTCard>
          </>
          : null
      }
    </>
  );
}

export default DashboardAdminSubbranchNewComponent
