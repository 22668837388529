import {create} from 'zustand'
import {postgrest} from '../../../../api/postgrest'
import {FormAction, FormInput, FormProps} from '../../../../interfaces/form-action-interfaces'
import {generateFormLogic} from '../../../../../_metronic/helpers/generate-form-logic'
import {toast} from 'react-toastify'
import {CsrLead, CsrLeadResult} from '../interfaces/csr-lead-result.interface'

interface CsrLeadState {
  csrLeadLoading: boolean
  items: CsrLead[]
  getCsrLead: (companyId: string) => Promise<void>
  onDelete: (id: string, companyId: string) => Promise<void>
}

export const useCsrLeadStore = create<CsrLeadState>((set, get) => ({
  csrLeadLoading: true,
  items: [],
  getCsrLead: async (companyId: string) => {
    set({csrLeadLoading: true})
    const response = await postgrest
      .from('csr_leads')
      .select('*')
      .eq('company_id', companyId)
      .is('deleted_at', null)
    const items = response as CsrLeadResult
    set({csrLeadLoading: false, items: items.data})
  },
  onDelete: async (id: string, companyId: string) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({csrLeadLoading: true})
      const response = await postgrest
        .from('csr_leads')
        .update({deleted_at: new Date()})
        .eq('id', id)
      if (response.status) {
        toast.success('Berhasil menghapus data')
      } else {
        toast.error('Gagal menghapus data')
      }
      get().getCsrLead(companyId)
      set({csrLeadLoading: false})
    }
  },
}))

interface CsrLeadFormState {
  field?: CsrLead
  setField?: (item: CsrLead) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam: FormProps
  reset: () => void
  onSubmit: (companyId: string) => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useCsrLeadFormStore = create<CsrLeadFormState>((set, get) => ({
  field: undefined,
  setField: (item: CsrLead) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms.push({
      id: 'pic_company',
      title: 'Nama Perusahaan',
      placeholder: 'Nama Perusahaan...',
      type: 'text',
      name: 'pic_company',
      value: get().field?.pic_company ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'pic_name',
      title: 'Nama PIC',
      placeholder: 'Nama PIC...',
      type: 'text',
      name: 'pic_name',
      value: get().field?.pic_name ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'pic_email',
      title: 'Email PIC',
      placeholder: 'Email PIC...',
      type: 'text',
      name: 'pic_email',
      value: get().field?.pic_email ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'pic_phone',
      title: 'No HP PIC',
      placeholder: 'No HP PIC...',
      type: 'text',
      name: 'pic_phone',
      value: get().field?.pic_phone ?? '',
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'estimated_value',
      title: 'Estimasi Donasi',
      placeholder: 'Estimasi Donasi...',
      type: 'currency',
      name: 'estimated_value',
      value: get().field?.estimated_value ?? 0,
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })
    forms.push({
      id: 'csr_mode',
      title: 'Mode CSR',
      placeholder: 'Mode CSR...',
      type: 'select-with-text',
      name: 'csr_mode',
      value: {
        label: get().field?.csr_mode,
        value: get().field?.csr_mode,
      },
      selectData: [
        {value: 'Direct Fund', label: 'Direct Fund'},
        {value: 'Fundraising', label: 'Fundraising'},
      ],
      disabled: formParam.action === FormAction.VIEW ? true : false,
      required: true,
    })

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (companyId: string) => {
    // set({ formLoading: true, submitDone: false });
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
        company_id: companyId,
        created_at: new Date(),
        updated_at: new Date(),
      }
    }

    if (get().formParam.action === FormAction.CREATE) {
      const res = await postgrest.from('csr_leads').insert(form)
      if (res.status === 201) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    } else {
      const res = await postgrest.from('csr_leads').update(form).eq('id', get().formParam.id)
      if (res.status === 204) {
        toast.success(res.statusText)
        set({submitDone: true, formLoading: false, field: undefined})
        return
      } else {
        toast.error(res.statusText)
        set({submitDone: false, formLoading: false})
        return
      }
    }
  },
}))
